

import './OpenAiTips.css';


export default function OpenAiTips (props) {



    return (
        <div>
            <div id="accordion" className='tip-accordion' >
            <div className="card tip-card" id={1} key={1}>
                    <div  className="card-header collapsed tip-header" id={`heading${1}`} data-toggle="collapse" data-target={"#collapse" + 1}>
                    <h5 className="mb-0 tip-h">
                        <button className="btn btn-link collapsed tip-button" data-toggle="collapse" data-target={"#collapse" + 1} aria-expanded="false" aria-controls="collapseOne">
                            <i style={{"fontFamily" : "Segoe UI", "fontStyle" : "normal"}} className="fi fi-rr-comments version-header tip-icon icon-centered"></i>
                            <span className='tip-text'>{'\xa0'.repeat(2)  + 'Tips for good results' }</span>
                        </button>
                    </h5>
                    </div>
                        <div id={"collapse" + 1} className="collapse" aria-labelledby="headingOne" data-parent="#accordion" style={{}}>
                        <div className="card-body tip-card-body">
                            <div className='vl' style={{"borderLeft":"5px solid #00B89F round", "borderBlockEnd":"rounded", "paddingLeft":"10px", "color": "#ECECEC"}}>
                                <p>
                                    For best results, verbosely describe the results you want. It works best to mention the number of colors that you want.
                                </p>
                                <p>
                                    You can describe the colors or starter colors you want, but it is also good to describe a scene or concept that they evoke. For example, you can say something like "I want a theme with 6 shades of purple. Use pastel sunset colors when you can" or "I need three different shades of yellow, which evoke a bunch of bananas at varying stages of ripeness".
                                </p>
                                <p>
                                    You can also describe how many of each shade you want. For example, saying "I want a total of 5 colors: 3 should be shades of orange, and two should be shades of teal" often works well.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}