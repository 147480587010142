import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './Signup.css';
import './css/shared.css'
import {useToken} from '../auth/useToken.js'
import axios from 'axios';
import SignupTeaser from './SignupTeaser';
import './css/sections.css';
import getAppInsights from '../utils/logging';

function Signup() {

    const [email, setEmail] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [conformPasswordValue, setConfirmPasswordValue] = useState();
    const [firstNameValue, setFirtNameValue] = useState();
    const [lastNameValue, setLastNameValue] = useState();
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const appInsights = getAppInsights();
    appInsights.loadAppInsights();
    
    useEffect(() => {
        appInsights.trackPageView();
    }, [appInsights])

    const navigate = useNavigate();
    const [token, setToken] = useToken();

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
    
    const onSignUpClicked = async () => {
        const emailValid = validateEmail(email);
        if (!emailValid) {
            setIsError(true);
            setErrorMessage("Please enter a valid email address.");
            return
        }else{
            setIsError(false);
        }
        const response = await axios.post('/api/signup', {
            email: email, 
            password: passwordValue,
            firstName: firstNameValue,
            lastName: lastNameValue
        }).catch(function (error) {
            setIsError(true);
            setErrorMessage("There is already an account with this email address.");
            return
            }
        
        ); 

        if(isError) {
            return
        }

        const {token} = response.data;
        setToken(token);
        navigate('/');
        window.location.reload(false);
        appInsights.trackEvent(
        {
            "name": "newUserSignup",
            "properties": {
                "source": "signupPage"
            }
        })
    }

    return (
        <div className="container-fluid" style={{"paddingTop": "100px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
            <div className='section-darkgray'>
                <div className="container">
                    <div style={{"paddingBottom": "15px"}}>
                        <div className="strike strike-white">
                            <span style={{"color": "white"}}>Sign Up</span>
                        </div>
                    </div>
                    <form style={{"display": "flex", "flexDirection": "column", "alignItems": "center"}}>
                        <div className="form-group center-block signup-form-group">
                            <label className="signup-label" htmlFor="firstNameInput">First Name</label>
                            <input onChange={(e) => setFirtNameValue(e.target.value)} width="25%" id='firstNameInput' name='firstName' className='form-control default-input signup-email-input' placeholder='enter first name'></input>
                        </div>
                        <div className="form-group center-block signup-form-group">
                            <label className="signup-label" htmlFor="lastNameInput">Last Name</label>
                            <input onChange={(e) => setLastNameValue(e.target.value)} width="25%" id='lastNameInput' name='firstName' className='form-control default-input signup-email-input' placeholder='enter last name'></input>
                        </div>
                        <div className="form-group center-block signup-form-group">
                            <label className="signup-label" htmlFor="emailinput">Email Address</label>
                            <input onChange={(e) => setEmail(e.target.value.toString().toLowerCase())} width="25%" id='emailinput' name='email' className='form-control default-input signup-email-input' placeholder='enter email'></input>
                            {
                            isError ?
                            <small className='signup-error-small'>{errorMessage}</small>
                            :<></>
                            }
                        </div>
                        <div className="form-group center-block signup-form-group">
                            <label className="signup-label" htmlFor="passwordinput">Password</label>
                            <input onChange={(e) => setPasswordValue(e.target.value)} type="password" width="25%" id='passwordinput' name='password' className='form-control default-input signup-password-input' placeholder='enter password'></input>
                        </div>
                        <div className="form-group center-block signup-form-group">
                            <label className="signup-label" htmlFor="confirmpasswordinput">Confirm Password</label>
                            <input onChange={(e) => setConfirmPasswordValue(e.target.value)} type="password" width="25%" id='confirmpasswordinput' name='confirmpassword' className='form-control default-input signup-password-input' placeholder='enter password'></input>
                        </div>
                        
                    </form>
                </div>
            </div>
            <div className='container-fluid section-darkgray'>
                <div className='container' style={{"display": "flex", "flexDirection": "column", "alignItems": "center"}}>
                    <div className="form-group signup-button-group">
                        <button onClick={ onSignUpClicked } disabled={!email || !passwordValue || !conformPasswordValue || passwordValue != conformPasswordValue || !firstNameValue || !lastNameValue } type="submit" className="yellow-default-button-primary">
                            Sign Up
                        </button>
                        <p><span className="login-link">Already have an account?  </span>
                            <Link to={"/login/"}>
                                <span className="login-link">
                                    Log In Here
                                </span>
                            </Link>
                        </p>
                        <small style={{"color": "#ECECEC"}}>By using this site you agree that you have read and will abide by our <a href='/terms'>terms and conditions</a>.</small>
                    </div>
                </div>
            </div>  
            <div className='section-darkgray'>
                <div className='container'>
                    <SignupTeaser callout="Create an account and sign in to:"/>
                </div>
            </div>
        </div>
    )
}

export default Signup;