import './Extract.css';
import './css/shared.css';
import './css/sections.css';
import { useUser } from '../auth/useUser';
import { useToken } from '../auth/useToken';
import axios from 'axios';
import {Form} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import compileThemeReducer from '../reducers/compileThemeReducer';
import { useNavigate, Link } from 'react-router-dom';
import TagEditor from './TagEditor';
import './css/buttons.css';
import '../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import extractColors from 'extract-colors';
import getAppInsights from '../utils/logging';
import Breadcrumb from './Breadcrumb';

export default function Extract() { 
    var FileSaver = require('file-saver');
    const user = useUser();
    const navigate = useNavigate();
    const [,setToken] = useToken();
    const [selectedFile, setSelectedFile] = useState();
    const [selectedFileUrl, setSelectedFileUrl] = useState();
    const [isFilePicked, setIsFilePicked] = useState();
    const [colors, setColors] = useState([]);
    const [distance, setDistance] = useState(0.15);
    const [dragActive, setDragActive] = useState(false);
    const [progressValue, setProgressValue] = useState("0");
    const [isThinking, setIsThinking] = useState(false);
    const [extractOptions, setExtractOptions] = useState();

    const crumbs = [
        {
            text: "Home",
            url: "/"
        },
        {
            text: "From Image",
            url: "/extract"
        }
    ]

    if (user) {
        const {id, email, info} = user;
    }

    const appInsights = getAppInsights();
    appInsights.loadAppInsights();
    
    useEffect(() => {
        appInsights.trackPageView();
    }, [appInsights])

    window.ondragover = function(e) { e.preventDefault(); return false };
    window.ondrop = function(e) { e.preventDefault(); return false };

    useEffect(() => {
        dragActive ? 
        document.getElementById('upload-target').classList.add('drag-active')
        :
        document.getElementById('upload-target').classList.remove('drag-active')
    }, [dragActive])

    const handleDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === "dragenter" || event.type ==="dragover") {
            setDragActive(true);
            document.getElementById('file-input').classList.add('hidden');
        }
        else if (event.type === "dragleave") {
            setDragActive(false);
            document.getElementById('file-input').classList.remove('hidden');
        }
    }
    
    useEffect(() => {
        setExtractOptions({
            pixels: 10000,
            distance: distance,
            saturationImportance: 0.2,
            splitPower: 10,
            colorValidator: (red, green, blue, alpha = 255) => alpha > 250
        })}, [distance]
    )

    function updateProgressBar(val){
        setTimeout(() => {
            setProgressValue(val)
        }, 250)
    }

    const handleCreate = () => {
        navigate('/create', {state: {"colors": colors}})
    }

    const refreshColors = async () => {
        setIsThinking(true);
        setProgressValue("5");
        setColors([]);
        setTimeout(() => {
            var options = {
                pixels: 10000,
                distance: distance,
                saturationImportance: 0.2,
                splitPower: 10,
                colorValidator: (red, green, blue, alpha = 255) => alpha > 250
            }
            extractColors(selectedFileUrl, options)
            .then((data) => setColors(data))
            .catch(console.error);
            setIsThinking(false);
        }, 1000)
        
        for (let i = 40; i <= 100; i++){
            updateProgressBar(i.toString())
        }
    }

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        document.getElementById('file-input').classList.remove('hidden');
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            setSelectedFile(event.dataTransfer.files[0]);
            let url = URL.createObjectURL(event.dataTransfer.files[0]);
            setSelectedFileUrl(url);
            setIsFilePicked(true);
            setIsThinking(true);
            setTimeout(() => {
                extractColors(url, extractOptions)
                .then((data) => setColors(data))
                .catch(console.error)
                updateProgressBar("40")
                updateProgressBar("60")
                updateProgressBar("100")
                setIsThinking(false);
            }, 1000);
          }
    }

    const fileChangeHandler =  (event) => {
        if(event.target.files[0]){
            setSelectedFile(event.target.files[0]);
            setSelectedFileUrl(URL.createObjectURL(event.target.files[0]));
            setIsFilePicked(true);
            setIsThinking(true);
            setTimeout(() => {
                extractColors(URL.createObjectURL(event.target.files[0]), extractOptions)
                .then((data) => setColors(data))
                .catch(console.error)
                updateProgressBar("40")
                updateProgressBar("60")
                updateProgressBar("100")
                setIsThinking(false);
            }, 1000);
            
        }
       
    }

    function Palette(props) {
        var colorPalette = props.colors;
        return (
            <div className='extract-palette-container'>
                {
                    colorPalette.map((color, key) => {
                        return(
                            <span key={key} className='extract-palette-cell' style={{"background": color.hex}}>
                                
                            </span>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <div className="container-fluid" style={{"paddingTop": "70px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
            <Breadcrumb crumbs={crumbs}></Breadcrumb>
            <div className='container'>
                <h3 className='extract-title'>
                    Extract a Theme from an Image
                </h3>
            </div>
            <div className='section-darkgray'>
                <div className='container'>
                    
                    <div id='upload-target' className='upload-target' onDragEnter={handleDrag} onDragLeave={handleDrag} onDrop={handleDrop} onDragOver={handleDrag}>
                        <h1 className='extract-target-text'>
                            Drop image file here, or
                        </h1>

                        <input id='file-input' type={'file'} onChange={fileChangeHandler}></input>

                        
                    </div>
                </div>
            </div>
            <div className='section-darkgray'>
                <div className='container'>
                    <progress className='extract-progress' value={progressValue} max="100"></progress>
                    <div className='row'>
                        <div className='col-lg-9 col-sm-12 extract-column'>
                            <div className='extract-image-container'>
                                {
                                        !selectedFile ? 
                                        <img className='extract-img' src='placeholder.jpg'></img>
                                        :
                                        <>
                                        <img className='extract-img' src={selectedFileUrl}></img>
                                        {
                                            isThinking ? 
                                            <div className='extract-think-fabric'>
                                                <div className="spinner-border extract-think-spinner" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                        </>
                                    

                                }

                            </div>
                        </div>
                        
                        <div className='col-lg-3 col-sm-12 extract-column'>
                            <div className='extract-settings-container'>
                                <h6 className='extract-settings-header'>Options</h6>
                                <hr></hr>
                                <div>
                                    <Form >
                                    <label style={{"textAlign": "left", "color": "#ECECEC", "fontSize": "large"}} htmlFor='palette-distance-input'>Distance</label>
                                    <Form.Control onChange={(e) => setDistance(parseFloat(e.target.value))} defaultValue={distance} id="palette-distance-input"  min="0" step="0.01" max="1" type='number' className='extract-palette-number-input'></Form.Control>
                                    <p style={{"color": "#ECECEC"}}>
                                        Reduce Distance to increase the number of colors returned.
                                    </p>
                                    </Form>
                                    <div style={{"display": "flex", "justifyContent": "center"}} >
                                        <button disabled={!isFilePicked} onClick={refreshColors} className='discard-theme-button extract-refresh-button' style={{"display" : "flex", "alignItems": "center", "justifyItems": "center", "alignSelf": "center"}}>Refresh{'\xa0'.repeat(2)}<i className="fi fi-rr-refresh icon-centered"></i></button>
                                    </div>
                                    {
                                        !isFilePicked ? 
                                        <small className='extract-settings-small'>Choose a file to edit settings or continue.</small>
                                        :<></>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        colors ?
                        <Palette colors={colors}/>
                        : <></>
                    }
                    
                    
                </div>
            </div>
           
                

            <div className='section-darkgray'>
                <div className='container' style={{"display": "flex", "flexDirection": "row", "justifyContent": "flex-end", "flexWrap": "wrap"}}>
                    <Link to='/'>
                        <button  className='discard-theme-button'>Discard</button>
                    </Link>
                    <button disabled={!colors || colors.length === 0} onClick={handleCreate}  className='yellow-default-button-primary' style={{"display" : "flex", "alignItems": "center", "justifyItems": "center"}}>Continue to Edit<i className="fi fi-rr-arrow-small-right icon-centered"></i></button>
                </div>
            </div>
            
        </div>
    )
}
