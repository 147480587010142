import './GenerateCard.css';
import { useNavigate } from 'react-router-dom';

export default function GenerateCard(props) {
    const colors = props.colors;
    const name = props.name;
    const buttonText = props.buttonText;
    const prompt = props.prompt;
    const navigate = useNavigate();

    const handleCreate = () => {
        navigate('/create', {state: {"colors": colors.map((color) => {return {"hex": color}}), "fromAI": true, "name": name}})
    }
     
    return(
        <div className='generate-card'>
            {
                name ? 
                <h6 className='generate-card-name'>{name}</h6>
                : <></>
            }
           <div className='generate-palette-container'>
            {colors.map((color, index)=> {
                        return(
                            <div id={`generate-theme-link-${index}`} key={index} className='generate-palette-cell' style={{"background": color}}>

                            </div>
                        )
                    })}
            </div>
            {
                prompt?
                <div>
                    <p className='prompt'><span className='prompt-prefix'>Prompt:</span>{`${'\xa0'.repeat(2)}"${prompt}"`}</p>
                </div>
                :
                <></>
            }
            <div style={{'display': 'flex', 'justifyContent': "flex-end"}}>
                <button onClick={() => handleCreate()} className='yellow-default-button-primary'>
                    { !buttonText ? `Use${'\xa0'.repeat(2)} ` : `${buttonText + '\xa0'.repeat(2)}`}
                    <i className="fi fi-rr-arrow-small-right"></i>
                </button>
            </div>
           
        </div>
    )
}