import './css/sections.css';
import { useUser } from '../auth/useUser';
import { useToken } from '../auth/useToken';
import axios from 'axios';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ThemeLink from './ThemeLink';
import '../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/icons/uicons-solid-rounded/css/uicons-solid-rounded.css';


export default function EmailVerificationLanding() { 
    const user = useUser();
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const { verificationString } = useParams();
    const [,setToken] = useToken();

    useEffect(() => {
        const loadVerification = async () => {
            try{
                const response = await axios.put('/users/verifyemail', { verificationString });
                const { token } = response.data;
                setToken(token);
                setIsSuccess(true);
                setIsLoading(false);
            }
            catch (e) {
                setIsSuccess(false);
                setIsLoading(false);
            }
        }

        loadVerification();
    }, [setToken, verificationString])

    return (
        <div className="container-fluid" style={{"paddingTop": "100px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
            <div className='section-default-yellow'>
                {
                    isLoading ? 
                    <h6>Loading...</h6>
                    :
                    <div className='container'>
                        {
                            isSuccess ? 
                            <div style={{"display": "flex", "justifyContent": "space-between", "width": "100%", "flexDirection": "row", "alignItems": "center"}}>
                                <h6 style={{"fontFamily": "Segoe UI", "fontSize": "1.15rem", "fontWeight": "400", "margin": "0", 'textAlign': "left"}}>{`Thanks for verifying your email!`}</h6>
                                <Link to={'/'}>
                                    <button className='black-primary-button'>Go to Home</button>
                                </Link>
                            </div>
                            :
                            <div style={{"display": "flex", "justifyContent": "space-between", "width": "100%", "flexDirection": "row", "alignItems": "center"}}>
                                <h6 style={{"fontFamily": "Segoe UI", "fontSize": "1.15rem", "fontWeight": "400", "margin": "0", 'textAlign': "left"}}>{`Unfortunately, verification has failed. Please return to your profile page and resend a link to try again.`}</h6>
                                <Link to={'/'}>
                                    <button className='black-primary-button'>Go to Home</button>
                                </Link>
                            </div>
                        }
                    </div>
                }

            </div>

        </div>
    )

}