import './Landing.css';
import '../components/css/actioncards.css';
import '../components/css/shared.css';
import { useUser } from '../auth/useUser';
import { useToken } from '../auth/useToken';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ThemeLink from '../components/ThemeLink';
import '../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/icons/uicons-solid-rounded/css/uicons-solid-rounded.css';
import ThemePreview from '../components/ThemePreview';
import getAppInsights from '../utils/logging';
import SignupTeaser from '../components/SignupTeaser';
import AiTeaser from '../components/AiTeaser';


export default function Landing() { 
    const user = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const [,setToken] = useToken();
    const [recentThemes, setRecentThemes] = useState();

    const appInsights = getAppInsights();
    appInsights.loadAppInsights();
    
    
    useEffect(() => {
        if (user) {
            navigate('/home', {from: location});
        }
    });

    // track page view after navigation to home has occurred, if it will occur
    useEffect(() => {
        appInsights.trackPageView();
    }, [appInsights])
    

    const handleCardHover = (iconId, cardId) => {
        let icon = document.getElementById(iconId);
        icon.classList.toggle('action-card-icon-hovered');
        let card = document.getElementById(cardId);
        card.classList.toggle('action-card-hovered');
    }

    const fetchThemes = async () => {
        const response = await axios.post('/themes/get/popular', {limit: 30});
        setRecentThemes(response.data);
    }

    useEffect(() => {
        fetchThemes();
    }, [])

    return (
        <div className="container-fluid" style={{"paddingTop": "70px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
            <div className='section-darkgray'>
                <div className='container landing-blurb-container'>
                    <span className='landing-blurb-span'>{`—${'\xa0'.repeat(2)}`}</span>
                    <p className='landing-blurb'>
                        Find, Create, and Share Power BI Report Themes
                    </p>
                    <span className='landing-blurb-span'>{`${'\xa0'.repeat(2)}—`}</span>
                </div>
            </div>
            <div className='section-darkgray'>
                <div className='container'>
                    <div className="strike strike-white">
                        <span className='strike-text-yellow'>Get Started</span>
                    </div>
                </div>
                <div className='container' style={{"paddingLeft": "0", "paddingRight": "0"}}>
                    {
                        process.env.REACT_APP_AI_TEASER_FIRST === "1" ? 
                        <AiTeaser/> :
                        <></>
                    }
                    <div className='action-container'>
                        {
                            [["Create Manually", "Make themes from scratch using the simple builder.", "fi-rr-plus", '/create'],
                                ["Extract from Image", "Upload an image from your device, or take a picture using your device's camera.", "fi-rr-picture", '/extract'],
                                ["Browse or Search", "Explore our library of public themes, and download them to use in your reports.", "fi-rr-search", '/browse']
                            ].map((action, key) => {
                                return (
                                    <Link to={action[3]} id={`card-${action}`} key={action[0]} onMouseEnter={() => handleCardHover(`card-icon-${action}`, `card-${action}`)} onMouseLeave={() => handleCardHover(`card-icon-${action}`, `card-${action}`)} className='action-card'>
                                                <h5 className='action-card-header'>{action[0]}</h5>
                                                <i id={`card-icon-${action}`} className={`fi ${action[2]} action-card-icon `}></i>
                                                <p className='action-card-description'>{action[1]}</p>
                                        </Link>
                                )
                            })
                        }
                        
                    </div>
                    {
                        process.env.REACT_APP_AI_TEASER_FIRST !== "1" ? 
                        <AiTeaser/> :
                        <></>
                    }
                </div>
            </div>
            <div style={{"marginTop":"0rem", 'marginBottom': "1rem"}} className='section-darkgray'>
                <div className='container'>
                    <div className="strike strike-white">
                        <span className='strike-text-yellow'>Sign In or Create an Account</span>
                    </div>
                    <div style={{"display": "flex", "justifyContent": "space-between", "width": "100%", "flexDirection": "column", "alignItems": "center", "paddingTop": "1rem"}}>
                        <h6 style={{"fontFamily": "Segoe UI", "fontSize": "1.15rem", "fontWeight": "400", "margin": "0", "color": "#ECECEC", "textAlign": "left"}}>
                            <p>
                                Account holders get access to the best features of Power Palette, including the ability to save and share themes, and create themes using OpenAI. Click the button to sign in!
                            </p>
                            <p>
                                You can review more benefits of creating an account below.
                            </p>
                        </h6>
                        <Link to={"/login"} style={{"textDecoration": "none"}}>
                            <button className='yellow-default-button-primary'>
                                <i className='fi fi-rr-sign-in-alt icon-centered'></i>
                                {'\xa0'.repeat(3)}
                                Sign In to Get Started
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='section-darkgray'>
                <div className='container'>
                    <div className="strike strike-white">
                        <span className='strike-text-yellow'>Popular Themes</span>
                    </div>
                    <div className='preview-container'>
                        {
                            recentThemes? 
                            recentThemes.map((theme, index)=>{
                                return(
                                    <ThemePreview key={index} theme={theme} type='like'/>
                                )
                            })
                            :
                            <></>
                        }
                    </div>
                    <div style={{"width": "100%", "textAlign": "left"}}>
                        <Link style={{"textDecoration": "none"}} to='/browse'>
                            <h5 style={{"display": "flex"}} ><span className='landing-empty-gallery-prompt'>Browse for more{'\xa0'}</span><i className="fi fi-rr-arrow-right icon-centered landing-gallery-prompt-icon"></i></h5>
                        </Link>
                    </div>
                </div>
                
            </div>
            <div className='section-darkgray'>
                    <div className='container'>
                        <div className="strike strike-white">
                            <span className='strike-text-yellow'>Become a Contributor</span>
                        </div>
                        <div style={{"paddingTop": "1rem"}}>
                            <SignupTeaser callout={`Registered users enjoy the following benefits (click each to see details)`} ></SignupTeaser>
                        </div>
                        
                        <div style={{"display": "flex", "justifyContent": "flex-end", "width": "100%"}}>
                            <button onClick={() => navigate("/signup", {from: location})} className='yellow-default-button-primary'>
                                <i className='fi fi-rr-user-add icon-centered'></i>
                                {'\xa0'.repeat(3)}
                                Create an Account
                            </button>
                        </div>
                    </div>
                

                </div>
        </div>
    )
}
