import './ThemeLink.css';
import { useUser } from '../auth/useUser';
import { useToken } from '../auth/useToken';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import '../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/icons/uicons-solid-rounded/css/uicons-solid-rounded.css';

export default function ThemeLink(props) {
    const theme = props.theme;

    const type = props.type;

    const handleLinkHover = () => {
        let icon = document.getElementById(`${type}-theme-link-icon-${theme._id}`);
        icon.classList.toggle('theme-link-arrow-visible');
    }

    return(
        <Link to={`/themes/view/${theme._id}`} style={{ textDecoration: 'none' }}>
            <div onMouseEnter={() => handleLinkHover()} onMouseLeave={() => handleLinkHover()} className='theme-link'>
                <div className='theme-link-palette-container'>
                    {theme.colors.map((color, index)=> {
                        return(
                            <div id={`${type}-theme-link-${theme._id}`} key={index} className='theme-link-palette-cell' style={{"background": color}}>

                            </div>
                        )
                    })}
                </div>
                <h6 className='theme-link-name'>{theme.name}</h6>
                <i id={`${type}-theme-link-icon-${theme._id}`} className='fi fi-rr-arrow-right theme-link-arrow'></i>
            </div>
        </Link>
    )
}