import { useUser } from './useUser';
import { Navigate, useLocation } from 'react-router-dom';

export default function RequireAuth({children}) {
    const user = useUser();
    const location = useLocation();

    if(!user) {
      return <Navigate to='/login' state={{from: location}} />
    }

    return children
  }