import './Explore.css';
import { useUser } from '../auth/useUser';
import '../components/css/shared.css';
import { useToken } from '../auth/useToken';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import '../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/icons/uicons-solid-rounded/css/uicons-solid-rounded.css';
import '../components/css/pagination.css';
import getAppInsights from '../utils/logging';
import './OpenAi.css';
import GenerateCard from '../components/GenerateCard';
import RecentPill from '../components/RecentPills';
import OpenAiTips from '../components/OpenAiTips';


export default function OpenAi() { 
    const user = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const [token,setToken] = useToken();
    const [userInput, setUserInput] = useState();
    const [results, setResults] = useState();
    const [thinking, setThinking] = useState(false);
    const [recents, setRecents] = useState();
    const [error, setError] = useState();

    const appInsights = getAppInsights();
    appInsights.loadAppInsights();
    
    useEffect(() => {
        appInsights.trackPageView();
    }, [appInsights])

    const getResults = async () => {
        setError(null);
        setThinking(true);
        var headers = {
            Authorization: `Bearer ${token}`
        }

        let response;

        try {
            response = await axios.post('/themes/openai', {prompt: userInput}, {headers: headers})
        }
        catch (e) {
            console.log(e)
            setError('There was an error generating the theme. Please try again with a more descriptive prompt.');
            setThinking(false);
            return;
        }
        
           

        appInsights.trackEvent(
            {
            "name": "openAiEvent",
            "properties": {
                "searchText": userInput
            }
            }
        )

        console.log(response.status)


        setResults(response.data)

        setThinking(false);
    }

    async function getRecentSearches() {
        var headers = {
            Authorization: `Bearer ${token}`
        }
        const recentSearches = await axios.post('/users/openai/recent', {}, {headers: headers});
        setRecents(recentSearches.data.results);  
    }

    useEffect(() => {
        getRecentSearches();
    }, [results])
    

    return (
        <div className="container-fluid dark-mode-fluid" style={{"paddingTop": "70px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
            <div className='container'>
            <div style={{"display": "flex", "flexDirection": "column", "justifyContent": "center", "alignContent": "center", "paddingTop": "4rem"}}>
                

                
                    <h1 className='open-ai-title'>
                        Generate a report theme using AI
                    </h1>
                
                    <div className="ai-search-input ">
                            <input onChange={(e) => setUserInput(e.target.value)} id='main-ai' className="ai-search-input-input"></input>
                            <i onClick={() => getResults()} id="search-icon" className="fi fi-rr-paper-plane-top ai-search-icon"></i>
                    </div>
                    {
                        error ?
                        <small className='ai-search-error'>
                            <i className='fi-rr-exclamation'></i>
                            {`${'\xa0'.repeat(2)} ${error}`}
                        </small>
                        : <></>
                    }
                    

                    {
                        recents && recents.length > 0 ? 
                        <h2 className='recent-pill-title'>Recall recents</h2>
                        : <></>
                    }
                    
                    <div className='recent-pill-container'>
                        {
                            recents ? 
                            recents.map((item, key) => {
                                return (
                                    <RecentPill key={key} item={item} command={setResults}></RecentPill>
                                )
                            }):<></>
                        }
                    </div>
                    
                    <div>
                        {
                            results && !thinking? 
                            <div className='generate-card-col'>
                                <GenerateCard colors = {results.colors} name={results.name} prompt={results.prompt} buttonText={`GO TO NEXT`}></GenerateCard>
                            </div>
                            :
                            thinking ? 
                            <div style={{"display": "flex", "justifyContent": "center", "width": "100%", "paddingTop": "2rem"}}>
                                <div className="spinner-border generate-think-spinner" style={{"color": "white"}} role="status">
                                <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </div>
                    <OpenAiTips></OpenAiTips>

                </div>
               
            </div>
        </div>
    )
}
