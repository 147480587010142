import { React , useEffect, useState} from 'react';
import './terms.css'

export default function Terms() {
    const [termBlurbs, setTermBlurbs] = useState();
    const [privacyBlurbs, setPrivacyBlurbs] = useState();

    useEffect( () => {
        fetch('/terms.json',
        {
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
        })
        .then((response) => response.json())
        .then((data) => setTermBlurbs(data.terms));
        }, []);

        useEffect( () => {
            fetch('/privacy_policy.json',
            {
                headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                 }
            })
            .then((response) => response.json())
            .then((data) => setPrivacyBlurbs(data.policies));
            }, []);

    if (termBlurbs && privacyBlurbs) {
        return (
            <div className="container-fluid" style={{"paddingTop": "80px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
                <div className='container'>
                    <h1 className='term-page-header'>Site Terms and Privacy Policy</h1>
                    <hr></hr>
                    <h2 className='term-section-header'>Terms and Conditions</h2>
                    <p className='term'>
                    Please read these terms and conditions ("terms and conditions", "terms") carefully 
                    before using the www.powerpalette.io website (“website”, "site", "service") operated by Power Palette LLC 
                    ("us", 'we", "our").
                    </p>
                    {
                        termBlurbs.map((term, key) => {
                            return (
                                    <>
                                    <h2 className='term-header'>{term.name}</h2>
                                    <p className='term'>
                                        {term.value}
                                    </p>
                                    </>
                            )
                        })
                    }
                    <h2 className='term-section-header'>Privacy Policy</h2>
                    <p className='term'>
                    This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from www.powerpalette.io (the “Site”).
                    </p>
                    {
                        privacyBlurbs.map((policy, key) => {
                            return (
                                    <>
                                    <h2 className='term-header'>{policy.name}</h2>
                                    <p className='term'>
                                        {policy.value}
                                    </p>
                                    </>
                            )
                        })
                    }
                    
                </div>
            </div>
        )
    }
    
}
