import { useEffect, useState } from 'react';
import './css/shared.css';
import { Link, useNavigate, useSearchParams, useParams } from 'react-router-dom';
import './ViewProfile.css';
import './css/sections.css';
import axios from 'axios';
import { useToken } from '../auth/useToken';
import { useUser } from '../auth/useUser';
import ThemePreview from './ThemePreview';
import getAppInsights from '../utils/logging';
import Breadcrumb from './Breadcrumb';

function ViewProfile() {
    const navigate = useNavigate();
    const [token, setToken] = useToken();

    const user = useUser();
    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams(); // sets the id const to the id of the user being viewed (not the user who is logged in)
    const [isUser, setIsUser] = useState(false); // true if the user being viewed is the user who is logged in
    const [userDetails, setUserDetails] = useState(null);
    const [userThemes, setUserThemes] = useState([]);
    const [isUserEvaluated, setIsUserEvaluated] = useState();
    const [deleteConfirmEmail, setDeleteConfirmEmail] = useState("")

    const appInsights = getAppInsights();
    appInsights.loadAppInsights();
    
    useEffect(() => {
        appInsights.trackPageView();
    }, [appInsights])

    const openDeleteModal = () => {
        document.getElementById('delete-modal').showModal();
    }

    const crumbs = [
        {
            text: "Home",
            url: "/"
        },
        {
            text: "Profile",
            url: `/profile/view/${id}`
        }
    ]

    const closeDeleteModal = () => {
        document.getElementById('delete-modal').close();
    }


    //function that fetches user details for the id in the querystring, and populates the userDetails object
    const getUser = async () => {
        var headers = {
            Authorization: `Bearer ${token}`
        }
        var response = await axios.post('/users/getdetails', {userId: id}, {headers: headers});
        if (response.status === 200){
            setUserDetails(response.data);
        }
    }

    //call the getUser function
    useEffect(() => {
        getUser();
    }, [id])

    //update the isUser statevalue whenever the id or userdetails are updated
    useEffect(() => {
        if (id && userDetails && user) {
            if (id === user.id) {
                setIsUser(true);
                setIsUserEvaluated(true);
            }
            else {
                setIsUser(false);
                setIsUserEvaluated(true);
            }
        }
        else {
            setIsUser(false);
        }
    },[id, userDetails, user])

    //fetch the themes for the user being viewed
    const fetchUserThemes = async () => {
        var headers = {
            Authorization: `Bearer ${token}`
        }
        //wait until isUser is assigned to avoid calling twice
        if(isUserEvaluated || !user){
            if (isUser) {
                const response = await axios.post('/themes/get/user', {userId: id}, {headers: headers});
                setUserThemes(response.data);
            }
            else{
                const response = await axios.post('/themes/get/user', {userId: id, sharedOnly : true}, {headers: headers});
                setUserThemes(response.data);
            } 
        }    
    }

    const resendVerificationEmail = async () => {
        await axios.post('/verification/email/resend', { id: user.id, email: user.email })
    }

    //call the fetch user themes when id or userDetails is changed
    useEffect(() => {
        fetchUserThemes();
    }, [id, userDetails, user, isUserEvaluated])

    const deleteUser = async () => {
        var headers = {
            Authorization: `Bearer ${token}`
        }
        const response = await axios.post(`/users/delete/${user.id}`, {body: {email: user.email}}, {headers: headers});
        if (response.status === 200) {
            localStorage.removeItem('token');
            navigate(`/`);
            window.location.reload(false);
        }
        else{

        }
    }

    return (
        <div className="container-fluid" style={{"paddingTop": "70px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
            <Breadcrumb crumbs={crumbs}></Breadcrumb>
            {
                userDetails && !userDetails.isVerified && isUser ? 
                <div className='section-darkgray'>
                    <div className='container'>
                        <div style={{"display": "flex", "justifyContent": "space-between", "width": "100%", "flexDirection": "row", "alignItems": "center"}}>
                            <h6 style={{"fontFamily": "Segoe UI", "fontSize": "1.15rem", "fontWeight": "400", "margin": "0", 'textAlign': "left", "color": "#ECECEC"}}>{`A verification email was sent to your email address on file at signup. Verify your email to make full use of this platform.`}</h6>
                            <button onClick={() => resendVerificationEmail() } className='yellow-default-button-primary'>Resend Email</button>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
            <div className='section-darkgray'>
                <div className="container">
                    {
                        userDetails ? 
                        <div style={{"paddingBottom": "15px"}}>
                            <div style={{'display': "flex", "flexDirection" : "row", "justifyContent": "space-between", "textAlign": "center", "alignItems": "center"}}>
                                <h1 className='profile-name'>{`${userDetails.firstName} ${userDetails.lastName}`}</h1>
                                {
                                    isUser ? 
                                        <Link to={`/profile/edit`} className={`${!userDetails.isVerified ? 'disabled-link' : ""}`} style={{"margin": "0px", "display": "block", "textDecoration": "none"}} state={{id: id}}>
                                            <button disabled={!userDetails.isVerified} className='edit-profile-button'>
                                                {
                                                    userDetails.isVerified ? 
                                                    "Edit Profile"
                                                    : 
                                                    <i className="fi fi-rr-lock icon-centered" style={{'fontStyle' : "normal"}}>Verify email to edit</i>
                                                }

                                            </button> 
                                        </Link>
                                    : <></>
                                }
                            </div>
                        </div>
                        : 
                        <></>
                    }
                </div>
            </div>
            <div className='section-darkgray'>
                <div className="container">
                    {
                        userDetails ? 
                        <div style={{"paddingBottom": "15px"}}>
                            <div className="strike strike-white">
                                <span className='strike-text-yellow' >Bio</span>
                            </div>
                            <div style={{"justifyContent" : "flex-start", "padding": "10px", "borderStyle": "solid", "borderRadius": "10px", "borderWidth": "1px", "marginBottom": "30px", "marginTop": "20px", "borderColor": "#727272"}}>
                                <p style={{"width" : "100%", "textAlign" : "left", "marginBottom": "40px", "color": "#ECECEC"}}>{userDetails.info.bio ? userDetails.info.bio : 'No bio yet.'}</p>
                            </div>
                            <div className="strike-white strike" >
                                <span className='strike-text-yellow'>Details</span>
                            </div>
                            <div style={{'textAlign': "left", "display": "flex", "flexDirection": "column"}}>
                                {
                                    isUser ? <i className="fi fi-rr-envelope profile-detail-icon icon-centered"> <span className='profile-detail'> {userDetails.email + `${'\xa0'.repeat(2)}(only visible to you)`}</span></i> : <></>
                                }
                                <i className="fi fi-rr-building profile-detail-icon icon-centered">  <span className='profile-detail'>{ userDetails.info.jobTitle && userDetails.info.jobTitle !== "" ?  `${userDetails.info.jobTitle} @ ${userDetails.info.company}` : userDetails.info.company && userDetails.info.company !== "" ? userDetails.info.company : '\xa0'.repeat(3)+  "No job info yet."}</span></i>
                            </div>
                        
                        </div>
                        :
                        <></>
                    }
                    
                </div>
            </div>
            <div className='section-darkgray'>
                {
                    userDetails ? 
                    <div className='container'>
                        <div className="strike strike-white">
                            <span className='strike-text-yellow' >{isUser ? "Your Themes" : `${userDetails.firstName}'s Themes`}</span>
                        </div>
                        <div className='preview-container'>
                            {userThemes.length > 0 ? 

                                userThemes.map((theme, index) => {
                                    return(
                                        <ThemePreview key={index} theme={theme} type='owned'></ThemePreview>
                                    )
                                })
                            :
                            <h5 style={{"fontSize": "1rem", "fontStyle": "italic", "fontFamily": "Segoe UI", "fontWeight": "400"}}>{'No themes to show.'}</h5>
                            }
                        </div>
                    </div>
                    :
                    <></>
                }
                
            </div>
            {
                isUser ? 
                <div className='section-darkgray'>
                    <div className='container'>
                        <div className="strike strike-white">
                            <span className='strike-text-yellow'>{`Manage Account`}</span>
                        </div>
                        <div style={{"display": "flex" , "padding": "1rem"}}>
                            <button  onClick={openDeleteModal} className='secondary-button-default'>{`Delete Account${'\xa0'}`}<i className="fi fi-rr-trash icon-centered"></i></button>
                        </div>
                        {/* delete modal  */}
                        <dialog id="delete-modal" className='delete-modal'>
                            <div>
                                <i onClick={() => document.getElementById('delete-modal').close()} className="fi fi-rr-cross-circle delete-modal-close-icon"></i>
                                <h2 style={{"fontFamily": "Segoe UI", "fontSize": "x-large", "color": "white"}}>Do you really want to delete your account?</h2>
                                <hr style={{"backgroundColor": "#ECECEC"}}></hr>
                                <p style={{"fontFamily": "Segoe UI", "color": "white"}}>You cannot recover your acount. Once it's gone, you'll permanently lose access to your themes, and they will be removed from the application.</p>
                                <br></br>
                                <form style={{"display": "flex", "flexDirection": "column", "alignItems": "center"}}>
                                    <label className="login-label"  htmlFor="emailinput">Confirm your email to delete your account.</label>
                                    <input style={{"maxWidth": "20rem"}} onChange={(e) => setDeleteConfirmEmail(e.target.value.toString().toLowerCase())} id='emailinput' name='name' className='form-control default-input login-email-input' placeholder='enter email'></input>
                                    {
                                        deleteConfirmEmail.toLowerCase() === userDetails.email.toLowerCase() ? 
                                        <></> :
                                        <small style={{"color": "orange"}}>Enter your email on file to delete your account.</small>
                                    }
                                </form>
                                <div style={{"display": "flex", "flexDirection": "column"}}>
                                    <button disabled={deleteConfirmEmail.toLowerCase() !== userDetails.email.toLowerCase()} onClick={deleteUser} style={{"display": "block"}} className='dark-mode-secondary-button button-tiny-font'>Delete permanently</button>
                                    <button onClick={() => document.getElementById('delete-modal').close()} className='primary-button-green button-tiny-font'><i style={{"display": "inline-block", "fontWeight": "650"}} className="fi fi-rr-shield-check icon-centered"></i>{`${'\xa0'}BACK TO SAFETY`}</button>
                                </div>
                            </div>
                        </dialog>
                    </div>
                </div>
                :
                <></>
            }
            
            
        </div>
    )
}

export default ViewProfile;