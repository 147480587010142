import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export default function getAppInsights() {
    const appInsights = new ApplicationInsights({ config: {
        connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
        instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY
        /* ...Other Configuration Options... */
      } });

    return appInsights
}