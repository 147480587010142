

import './SignupTeaser.css';


export default function SignupTeaser (props) {

    const callout = props.callout ?? "Perks of signing in:"


    const perks = [
        {
            "name" : "Create a public profile",
            "description" : "Build out your profile to showcase your stunning work! Profiles are accessible to any users, but your private themes will be shown to only you."
        },
        {
            "name" : "Leverage premium features",
            "description" : "Authenticated users have access to premium features, such as the ability to generate a theme from descriptive text or share their designs with anyone."
        },
        {
            "name" : "Save your themes",
            "description" : "Once you have signed in, you'll be able to save your themes directly in the app! You can find any saved themes under your profile, and download or edit them at any time."
        },
        {
            "name" : "Share your work with others",
            "description" : "Once you create a theme, you can set it as public from the View Theme page. This will make it discoverable to other users through search or by browsing, as well as allow you to share the link to the theme with other users, even those who have not created accounts."
        }
    ]

    var perksGallery = perks.map(function(perk, index){
        return (
                <div className="card perk-card" id={index} key={index}>
                    <div  className="card-header collapsed perk-header" id={`heading${perk.name}`} data-toggle="collapse" data-target={"#collapse" + index}>
                    <h5 className="mb-0 perk-h">
                        <button className="btn btn-link collapsed perk-button" data-toggle="collapse" data-target={"#collapse" + index} aria-expanded="false" aria-controls="collapseOne">
                            <i style={{"fontFamily" : "Segoe UI", "fontStyle" : "normal"}} className="fi fi-rr-badge-check version-header perk-icon icon-centered"></i>
                            <span className='perk-text'>{'\xa0'.repeat(2)  + perk.name }</span>
                        </button>
                    </h5>
                    </div>
                        <div id={"collapse" + index} className="collapse" aria-labelledby="headingOne" data-parent="#accordion" style={{}}>
                        <div className="card-body perk-card-body">
                            <div className='vl' style={{"borderLeft":"5px solid #00B89F round", "borderBlockEnd":"rounded", "paddingLeft":"10px", "color": "#ECECEC"}}>
                                {perk.description}
                            </div>
                        </div>
                    </div>
                </div>
                
        )})

    return (
        <div>
            <h1 className='perk-callout'>{callout}</h1>
            <div id="accordion" className='perk-accordion' >
                {perksGallery}
            </div>
        </div>
    )
    
}