
import './ViewTheme.css';
import './css/shared.css';
import { useUser } from '../auth/useUser';
import { useToken } from '../auth/useToken';
import axios from 'axios';
import Breadcrumb from './Breadcrumb.js';
import {Form} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams, Link } from 'react-router-dom';
import '../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/icons/uicons-solid-rounded/css/uicons-solid-rounded.css';
import compileThemeReducer from '../reducers/compileThemeReducer';
import tinycolor from 'tinycolor2';
import getAppInsights from '../utils/logging';



export default function ViewTheme() {
    var FileSaver = require('file-saver');
    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams();
    const [token, setToken] = useToken();
    const [theme, setTheme] = useState();
    const [themeAuthor, setThemeAuthor] = useState();
    const [isLiked, setIsLiked] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const user = useUser();
    const navigate = useNavigate();
    const [isShared, setIsShared] = useState(false);
    const [deletePressed, setDeletePressed] = useState(false);
    const [denyView, setDenyView] = useState(false);
    const [fontPreview, setFontPreview] = useState(false);
    const [displayFont, setDisplayFont] = useState('Segoe UI');
    const crumbs = [
        {
            text: "Home",
            url: "/"
        },
        {
            text: "Themes",
            url: "/browse"
        },
        {
            text: `View`,
            url: `/themes/view/${id}`
        }
    ]

    const appInsights = getAppInsights();
    appInsights.loadAppInsights();
    
    useEffect(() => {
        appInsights.trackEvent(
            {
            "name": "viewThemeEvent",
            "properties": {
                "themeId": id,
                "source" : "viewPage"
            }
            }
        )
        appInsights.trackPageView();
    }, [appInsights])

    const handleLikeTheme = async () => {
        var headers = {
            Authorization: `Bearer ${token}`
        }
        const reqBody = {
            userId: user.id,
            themeId: id,
            add: isLiked ? false : true
        }

        try {
            const response = await axios.post('/users/likes/update', reqBody, {headers: headers}); 
            setIsLiked(!isLiked);
        }
        catch {
            return
        }
        
    }

    const handleFontPreviewPressed = () => {
        if(fontPreview){
            setDisplayFont('Segoe UI');
        }
        else{
            setDisplayFont(theme.font);
        }
        document.getElementById('font-try-link').classList.toggle('try-link-active');
        setFontPreview(!fontPreview)
    }


    const handleDownloadClicked = async () => {
        appInsights.trackEvent(
            {
            "name": "downloadThemeEvent",
            "properties": {
                "themeId": id
            }
            }
        )
        await axios.post('/themes/download/log', {themeId: id, themeName: theme.name, userId: user ? user.id : null});
        if(theme) {
            let jsonData = compileThemeReducer(theme);
            var fileName = `${theme.name}.json`;
            let fileToSave = new Blob([jsonData], {
                type: 'application/json'
            });
            FileSaver.saveAs(fileToSave, fileName)
        }
        
    }

    function checkIfLiked(likes) {
        var likedThemes = likes.likedThemes;
        if (likedThemes.includes(id)){
            setIsLiked(true)
        }
        else{
            setIsLiked(false)
        }
    }

    useEffect(() => {
        if(user){
            let body = {
                userId: user.id
            }
            fetch('/users/likes/get', 
            {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((data)=>checkIfLiked(data));
        }   
    }, [])

    const getTheme = async () => {
        const response = await axios.post('/themes/get', {id: id}); 
        setTheme(response.data);
        setIsShared(response.data.isShared ? response.data.isShared : false);
    }

    //set is owner
    useEffect(() => {
        if(theme && user){
            if(theme.userId==user.id){
                setIsOwner(true);
            }
        }
    }, [theme])

    const getThemeAuthor = async () => {
        if (theme){
            const response = await axios.post('/users/get', {id: theme.userId}); 
            setThemeAuthor(response.data);
        }
    }

    useEffect(() => {
        getTheme();
    }, [id])

    useEffect(() => {
        getThemeAuthor();
    }, [theme])

    const handleThemeShare = async (e) => {
        var headers = {
            Authorization: `Bearer ${token}`
        }
        var action = e.target.checked;
        setIsShared(action);
        await axios.post('/themes/share', {themeId: id, action: action}, {headers: headers});

    }

    const handleReturnClicked = () => {
        navigate('/');
    }

    const handleThemeDelete = async () => {
        var headers = {
            Authorization: `Bearer ${token}`
        }
        await axios.post('/themes/delete', {id: id, uerId: user.id}, {headers: headers});
        navigate('/');
    }

    if(theme && themeAuthor) {
        if(!isOwner && !theme.isShared) {
            return(
                <div className="container-fluid" style={{"paddingTop": "70px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
                    <div className='section-lightgray'>
                        <h2>
                            It looks like this theme isn't shared with you, or is no longer available.
                        </h2>
                        <button className='view-download-theme-button' onClick={handleReturnClicked }>Back to Home</button>
                    </div>
                </div>
            )
        }
    }
    
    return(
        <div className="container-fluid" style={{"paddingTop": "70px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
            <Breadcrumb crumbs={crumbs}></Breadcrumb>
            <div className='section-darkgray'>
                <div className='container'>
                        {/* <p>{id}</p>
                        <p>{JSON.stringify(theme)}</p>
                        <p>{JSON.stringify(themeAuthor)}</p> */}
                    {
                        (theme && themeAuthor)  ? 
                            <div className='theme-view-card'>
                                <h1 align='left' style={{"fontFamily": displayFont}} className="theme-view-name">
                                    {theme.name}
                                </h1>
                                {
                                    user ? 
                                    <i onClick={handleLikeTheme} id="likeThemeIcon" className={`fi ${isLiked? 'fi-sr-heart' : 'fi-rr-heart'} like-theme-icon`}></i>
                                    :
                                    <></>
                                }
                                
                                <div className='palette-container'>
                                    {
                                        theme.colors.map((color, index) => {
                                            if (index===0 && theme.colors.length==1) {
                                                return(
                                                <div key={color+index} id={"palette-"+ color + index} className='palette-cell' style={{"background": color, "borderRadius": "10px 10px 10px 10px"}}>
                                                    
                                                </div>
                                                )
                                            }
                                            if (index===0){
                                                return(
                                                <div key={color+index} id={"palette-"+ color + index} className='palette-cell' style={{"background": color, "borderRadius": "10px 0px 0px 10px"}}>
                                                    
                                                </div>
                                                )
                                                }
                                            if (index===theme.colors.length-1){
                                                return(
                                                    <div key={color+index} id={"palette-"+ color + index} className='palette-cell' style={{"background": color, "borderRadius": "0px 10px 10px 0px"}}>
                                                    </div>
                                                )
                                            }
                                            return(
                                                
                                                <div key={color+index} id={"palette-"+ color + index} className='palette-cell' style={{"background": color}}>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div style={{"padding": "1rem"}} >
                                    <h2 align='left' style={{"fontFamily": displayFont, "display": "flex"}} className='theme-detail'>
                                        {'Font:' + '\xa0' + theme.font + "\xa0"}
                                        <span id='font-try-link' onClick={handleFontPreviewPressed}  className='try-link'>
                                            {
                                                !fontPreview ? 
                                                `click to preview ${'\xa0'.repeat(1)}`
                                                :
                                                <>
                                                {`click to remove preview ${'\xa0'.repeat(1)}`}
                                                <i className="fi fi-rr-eye icon-centered"></i>
                                                </>
                                            }
                                        </span>
                                    </h2>
                                    <Link className='theme-detail' to={`/profile/view/${theme.userId}`}>
                                        <h2 align='left' style={{"fontFamily": displayFont}} className='theme-detail'>
                                            {"Author:" + '\xa0' + themeAuthor.firstName + '\xa0' + themeAuthor.lastName + (isOwner ? ' (you)' : "")}  <i className="fi fi-rr-link-alt" style={{'fontSize' : "15px"}}></i>
                                        </h2>
                                    </Link>
                                    {
                                        tinycolor(theme.pageBackground).isLight() ?
                                        <h2 align='left' style={{"fontFamily": displayFont, "borderRadius" : "2px", "outlineWidth": "1px", "outlineStyle": "solid", "backgroundColor": theme.pageBackground, "width": "max-content", "color" : "black"}} className='theme-detail'>
                                            {"Page Background:" + '\xa0' + theme.pageBackground + '\xa0' + (theme.backgroundTransparency ?  `(${theme.backgroundTransparency}%)` : "")}
                                        </h2>
                                        :
                                        <h2 align='left' style={{"fontFamily": displayFont, "borderRadius" : "2px", "outlineWidth": "1px", "outlineStyle": "solid", "backgroundColor": theme.pageBackground, "color": "white", "padding": "2px", "width": "max-content"}} className='theme-detail'>
                                            {"Page Background:" + '\xa0' + theme.pageBackground + '\xa0' + (theme.backgroundTransparency ?  `(${theme.backgroundTransparency}%)` : "")}
                                        </h2>
                                    }
                                    <h2 align='left' style={{"fontFamily": displayFont}} className='theme-detail'>
                                        {`${theme.downloadCount ? theme.downloadCount : 0} download${theme.downloadCount ? (theme.downloadCount == 1 ? "" : 's') : 's'}`}
                                    </h2>
                                    {
                                        theme.fromImage ?
                                        <h2 align='left' style={{"fontFamily": displayFont, "display": "flex"}} className='theme-detail'>
                                            <i className="fi fi-rr-picture icon-centered"></i>{'\xa0'}Colors from image{'\xa0'}
                                            {
                                                !isOwner ? 
                                                <span onClick={() => navigate('/extract')} className='try-link'>try it<i className="fi fi-rr-arrow-small-right icon-centered"></i></span>
                                                :<></>
                                            }
                                            
                                        </h2>
                                        :<></>
                                    }
                                    {
                                        theme.fromAi ? 
                                        <h2 align='left' style={{"fontFamily": displayFont, "display": "flex"}} className='theme-detail'>
                                            <i className="fi fi-rr-brain-circuit icon-centered"></i>{'\xa0'}Generated using AI{'\xa0'}
                                            {
                                                !isOwner ? 
                                                <span onClick={() => navigate('/ai')} className='try-link'>try it<i className="fi fi-rr-arrow-small-right icon-centered"></i></span>
                                                :<></>
                                            }
                                            
                                        </h2>
                                        : <></>
                                    }
                                    
                                    {
                                        isOwner?
                                        <>
                                            <hr></hr> 
                                            <div style={{'display': "flex", "flexDirection": "row", "alignItems": "flex-start"}} className="custom-control custom-switch">
                                                <input onChange={(e) => handleThemeShare(e)}  style={{"transform": "scale(2.5) !important"}} type="checkbox" checked={isShared} className="custom-control-input share-switch" id="customSwitch1"/>
                                                <label style={{"fontFamily": displayFont}} className="custom-control-label share-switch-label" htmlFor="customSwitch1">Shared publicly</label>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    
                                </div>
                                <div>
                                    {
                                        theme.tags ? 
                                        <div className='tag-container'>
                                            {theme.tags.map((tag, key) => {
                                                return(
                                                <span key={key} className='theme-tag'>{tag.toLowerCase()}</span>
                                                )
                                            })}
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                {
                                    isOwner ? 
                                    <div style={{"display" : "flex", "paddingTop": "1rem",  "justifyContent": "flex-end", "alignContent": "center", "flexWrap": "wrap"}}>
                                        {
                                            !deletePressed ? 
                                            <i className="fi fi-rr-trash delete-icon" onClick={() => setDeletePressed(true)}></i>
                                            :
                                            <>
                                                <h6 className='delete-warning'>Are you sure you want to permanently delete this theme?</h6>
                                                <button onClick={() => handleThemeDelete() } className='delete-confirm-button'><i className="fi fi-rr-shield-exclamation delete-button-icon icon-centered">{'\xa0'}Yes, Delete Permanently</i></button>
                                                <button onClick={() => setDeletePressed(false)} className='delete-cancel-button'><i className="fi fi-rr-shield-check delete-button-icon icon-centered">{'\xa0'}No, Cancel This Action</i></button>
                                            </>
                                        }
                                        
                                    </div>
                                    :
                                    <></>
                                }
                                
                                
                            </div>
                        :
                            <div className="spinner-grow" role="status" style={{"width": "2rem", "height": "2rem"}}>
                                <span className="sr-only">Loading...</span>
                            </div>
                        
                    }
                    
                </div>
            </div>
            <div className='section-darkgray'>
                <div className='container'>
                    <div className='button-group'>
                        {
                            isOwner ?
                            <Link to={`/edit/${theme._id}`} style={{ textDecoration: 'none' }}>
                                <button className='edit-theme-button'>Edit</button>
                            </Link>
                            :
                            <></>
                        }
                        <button className='view-download-theme-button' onClick={handleDownloadClicked }>Download</button>
                    </div>
                </div>
            </div>
        </div>
    )
}