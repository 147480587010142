import './Breadcrumb.css';

import { useState, useEffect } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';

export default function Breadcrumb({crumbs}) {

    function breadcrumbPill(crumb, index) {

        return(
            <>
                {
                    index ? 
                    <i className="fi fi-rr-angle-small-right icon-centered breadcrumb-divider"></i>
                    :
                    <></>
                }
                <Link style={{"textDecoration": "none"}} to={crumb.url}>
                    <span className='breadcrumb-pill'>
                        <p className='breadcrumb-text'>{crumb.text}</p>
                    </span>
                </Link>
            </>
        )

    }

    return (
        <div className='breadcrumb-wrapper'>
            {
                crumbs.map((crumb, index) => breadcrumbPill(crumb, index))
            }
        </div>
    )
}