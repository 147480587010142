import './Home.css';
import '../components/css/actioncards.css';
import './css/shared.css';
import { useUser } from '../auth/useUser';
import { useToken } from '../auth/useToken';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ThemeLink from './ThemeLink';
import ThemePreview from './ThemePreview';
import '../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/icons/uicons-solid-rounded/css/uicons-solid-rounded.css';
import getAppInsights from '../utils/logging';


export default function Home() { 
    const user = useUser();
    const [token, setToken] = useToken();
    const [userThemes, setUserThemes] = useState([]);
    const [likes, setLikes] = useState([]);
    const [likedThemes, setLikedThemes] = useState([]);

    const appInsights = getAppInsights();

    appInsights.loadAppInsights();
     
    const {id, email, info} = user;

    useEffect(() => {
        appInsights.trackPageView();
    }, [appInsights])

    const handleCardHover = (iconId, cardId) => {
        let icon = document.getElementById(iconId);
        icon.classList.toggle('action-card-icon-hovered');
        let card = document.getElementById(cardId);
        card.classList.toggle('action-card-hovered');
    }

    const fetchUserThemes = async () => {
        var headers = {
            Authorization: `Bearer ${token}`
        }
        const response = await axios.post('/themes/get/user', {userId: user.id}, {headers: headers});
        setUserThemes(response.data);
    }

    const fetchLikedThemes = async () => {
        const response = await axios.post('/themes/get/multiple', {ids: likes});
        setLikedThemes(response.data);
    }

    const fetchLikes = async () => {
        const response = await axios.post('/users/likes/get', {userId: user.id});
        setLikes(response.data.likedThemes);
    }

    useEffect(() => {
        fetchLikes();
    }, [user])

    useEffect(() => {
        fetchUserThemes();
    }, [user])

    useEffect(() => {
        if (likes.length > 0){
            fetchLikedThemes();
        }
    }, [likes])


    return (
        <div className="container-fluid" style={{"paddingTop": "80px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
            <div className='container'>
                <h1 className='home-title'>{ user ? `Welcome,${'\xa0'.repeat(2)}${user.firstName} ${user.lastName}!` : "Welcome!"}</h1>
            </div>
            <div className='section-darkgray'>
                <div className='container home-action-container-parent'>
                    <div className="strike strike-white" >
                        <span style={{"color": "white"}}>Jump Back In</span>
                    </div>
                        
                    </div>
                    <div className='container' style={{"paddingLeft": "0", "paddingRight": "0"}}>
                        <div className='action-container'>
                            {
                                [["Use OpenAI", "Describe your ideal theme in detail, and get a creative, beautiful color palette just for you.", "fi-rr-brain-circuit", `/ai`],
                                ["Extract from Image", "Upload an image from your device, or take a picture using your device's camera.", "fi-rr-picture", '/extract'],
                                ["Create Manually", "Make themes from scratch using the simple builder.", "fi-rr-plus", '/create'],
                                ].map((action, key) => {
                                    return (
                                        <Link to={action[3]} id={`card-${action}`} key={action[0]} onMouseEnter={() => handleCardHover(`card-icon-${action}`, `card-${action}`)} onMouseLeave={() => handleCardHover(`card-icon-${action}`, `card-${action}`)} className='action-card'>
                                                <h5 className='action-card-header'>{action[0]}</h5>
                                                <i id={`card-icon-${action}`} className={`fi ${action[2]} action-card-icon `}></i>
                                                <p className='action-card-description'>{action[1]}</p>
                                            {
                                                action[0] === "Use OpenAI" ? 
                                                <span className='action-card-new-tag'>
                                                    <i className='fi-rr-sparkles'></i>
                                                    {'\xa0'.repeat(2)}
                                                    New
                                                    </span>
                                                :<></>
                                            }
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>
            </div>
            <div style={{"marginTop":"2rem"}} className='section-darkgray'>
                <div className='container'>
                    <div style={{"display": "flex", "justifyContent": "space-evenly", "width": "100%", "flexDirection": "column", "alignItems": "center"}}>
                        <h6 style={{"fontFamily": "Segoe UI", "fontSize": "1.15rem", "fontWeight": "400", "margin": "0", "color": "white"}}>Find inspiring themes in our library of submissions by your fellow creators!</h6>
                        <Link to={'/browse'}>
                            <button className='yellow-default-button-primary' style={{"whiteSpace" : "nowrap"}}>Browse for Ideas</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div style={{"marginTop":"2rem"}} className='home-section-darkgray'>
                <div className='container'>
                    <div className="strike strike-white">
                        <span style={{"color": "white"}}>Your Themes</span>
                    </div>
                    <div>
                            {userThemes.length > 0 ? 
                            userThemes.map((theme, index) => {
                                return(
                                    <ThemeLink key={index} theme={theme} type='author'></ThemeLink>
                                )
                            })
                            : 
                            <div style={{"width": "100%", "textAlign": "left"}}>
                                <h5 style={{"fontSize": "1rem", "fontStyle": "italic", "fontFamily": "Segoe UI", "fontWeight": "400", 'textAlign': "left", "color": "#ececec"}}>You haven't created any themes yet. Click 'Create' to get started!</h5>
                                <Link style={{"textDecoration": "none"}} to='/create'>
                                    <h5 style={{"display": "flex"}} ><span className='home-empty-gallery-prompt'>Create a Theme{'\xa0'}</span><i className="fi fi-rr-arrow-right icon-centered home-gallery-prompt-icon"></i></h5>
                                </Link>
                            </div>
                            }
                        </div>
                </div>
            </div>
            <div className='section-darkgray' style={{"paddingBottom": "2rem", "marginBottom": "2rem"}}>
                <div className='container'>
                    <div className="strike strike-white">
                        <span style={{"color": "white"}}>Themes You Like</span>
                    </div>
                    <div className='preview-container'>
                        {likedThemes.length > 0 ? 
                                likedThemes.map((theme, index) => {
                                    return(
                                        // <ThemeLink key={index} theme={theme} type='liked'></ThemeLink>
                                        <ThemePreview key={index} theme={theme} type='liked'></ThemePreview>
                                    )
                                })
                                :
                                <div style={{"width": "100%", "textAlign": "left"}}>
                                    <h5 style={{"fontSize": "1rem", "fontStyle": "italic", "fontFamily": "Segoe UI", "fontWeight": "400", "color": "#ececec"}}>You haven't liked any themes yet.</h5>
                                    <Link style={{"textDecoration": "none"}} to='/explore'>
                                        <h5 style={{"display": "flex"}} ><span className='home-empty-gallery-prompt'>Browse{'\xa0'}</span><i className="fi fi-rr-arrow-right icon-centered home-gallery-prompt-icon"></i></h5>
                                    </Link>
                                </div>
                            }
                    </div>
                </div>
            </div>
            
            
        </div>
    )
}
