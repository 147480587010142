import { useUser } from "../auth/useUser";
import "./css/shared.css";
import { useToken } from "../auth/useToken";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ThemeLink from "./ThemeLink";
import "../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css";
import "../external/icons/uicons-solid-rounded/css/uicons-solid-rounded.css";
// import "https://cdn.botframework.com/botframework-webchat/latest/webchat.js";
import { WebChat } from 'botframework-webchat';
import ThemePreview from "./ThemePreview";
import ReactPaginate from "react-paginate";
import "./css/pagination.css";
import getAppInsights from "../utils/logging";
import "./Help.css";

export default function Help() {
  const user = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [, setToken] = useToken();
  const [recentThemes, setRecentThemes] = useState();
  const [recentThemesSplit, setRecentThemesSplit] = useState();
  const [currentItems, setCurrentItems] = useState();
  const [currentRange, setCurrentRange] = useState();
  const [currentIndex, setCurrentIndex] = useState();
  const [botToken, setBotToken] = useState();
  const [tokenLoaded, setTokenLoaded] = useState(false);

  useEffect(() => {
    const loadToken = async () => {
      var response = await axios.post("/users/bot/token/get");
      setBotToken(response.data.token);
    };
    loadToken();

    setTokenLoaded(true);
  }, []);

  const appInsights = getAppInsights();
  appInsights.loadAppInsights();

  const styleSet = window.WebChat.createStyleSet({
    bubbleBackground: "white",
    bubbleFromUserBackground: "white",
    rootHeight: "500px",
    rootWidth: "100%%",
    bubbleBorderRadius: "15px",
    bubbleFromUserBorderRadius: "15px",
    borderStyle: "solid",
    borderWidth: "1px",
    backgroundColor: "white",
    accent: "white"
  });

  styleSet.textContent = {
    ...styleSet.textContent,
    fontFamily: "'Segoe UI Light', Tahoma, Geneva, Verdana, sans-serif",
    fontWeight: "bold",
    color: "black",
  };

  const avatarOptions = {
    botAvatarInitials: "PP",
    botAvatarImage: 'artificial-intelligence.svg',
    userAvatarImage: 'user.svg',
    userAvatarInitials: "",
    userAvatarFill: "white"
  };

  const insertChat = (value) => {
    var event = new Event("keypress");
    var _input = document.querySelector(".webchat__send-box-text-box__input");

    _input.focus();

    _input.value = value;
    event.key = "Enter";
    _input.dispatchEvent(event);
    _input.focus();

    // var _form = document.querySelector(".webchat__send-box-text-box");
    // _form.submit();
  };

  useEffect(() => {
    if (botToken) {
      window.WebChat.renderWebChat(
        {
          directLine: window.WebChat.createDirectLine({
            token: botToken,
          }),
          styleSet,
          styleOptions: avatarOptions,
        },
        document.getElementById("webchat")
      );
    }
  }, [tokenLoaded, botToken]);

  return (
    <div
      className="container-fluid"
      style={{ paddingTop: "80px", paddingLeft: "0px", paddingRight: "0px" }}
    >
      <div className="section-darkgray">
        <div className="container ">
          <div className="strike strike-white">
            <span
              className="strike-text-white"
            >
              Get Help
            </span>
          </div>
        </div>
      </div>
      <div className="section-darkgray">
        <div className="container">
          <h1 className="bot-title">Power Palette QnA Bot</h1>
          {botToken ? (
            <>
              {/* <p>{botToken}</p> */}
              <div className="bot" id="webchat" role={"main"}></div>
            </>
          ) : (
            <></>
          )}

          <div style={{ paddingTop: "1rem", textAlign: "left", "paddingBottom": "1rem" }}>
            <h2 className="tip-header">Try asking...</h2>
            <span
              onClick={() => insertChat("How many colors should I use?")}
              className="tip-span"
            >
              How many colors should I use?
            </span>
            {/* onClick={(v='How many colors should I use?') => insertChat(v)} */}
            <span
              onClick={() => insertChat("Do I have to create an account?")}
              className="tip-span"
            >
              Do I have to create an account?
            </span>
            <span
              onClick={() => insertChat("Are my themes private?")}
              className="tip-span"
            >
              Are my themes private?
            </span>
          </div>
        </div>
      </div>
      <div className="section-darkgray">
        <div className="container">
        <div className="strike strike-white">
            <span
              style={{
                color: "white",
                fontSize: "25px",
              }}
            >
              Import into Power BI
            </span>
          </div>
          <img className="demo-gif" src="/demo.gif"></img>
        </div>
      </div>
    </div>
  );
}
