import "./css/shared.css";
import "./css/ai-teaser.css";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

export default function AiTeaser() {
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (destination) => navigate(`/ai`, { replace: false }),
    [navigate]
  );

  return (
    <div className="ai-teaser">
      <h3>
        <i className="fi fi-rr-magic-wand icon-centered"></i>
        {"\xa0".repeat(2)}
        Create beautiful themes in seconds using AI
      </h3>
      <p>
        Use Generative AI to create report themes
        automatically from text prompts. Use the link below to unleash your creativity!
      </p>
      <div className="ai-teaser-button-container">
        <button
          onClick={handleNavigate}
          className="yellow-default-button-primary"
        >
          Use AI for Free
          {"\xa0".repeat(2)}
          <i className="fi fi-rr-arrow-right icon-centered"></i>
        </button>
      </div>
    </div>
  );
}
