import './Create.css';
import './css/shared.css';
import { useUser } from '../auth/useUser';
import { useToken } from '../auth/useToken';
import axios from 'axios';
import {Form, FormGroup} from 'react-bootstrap';
import { useState, useEffect, useReducer } from 'react';
import fontReducer from '../reducers/fontReducer';
import compileThemeReducer from '../reducers/compileThemeReducer';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { colorCardDeleteAnimate, colorCardDeleteAnimateReset } from './js/shared';
import TagEditor from './TagEditor';
import './css/buttons.css';
import '../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import getAppInsights from '../utils/logging';
import Breadcrumb from './Breadcrumb';
import { v4 } from 'uuid';

export default function Create() { 
    var FileSaver = require('file-saver');
    const user = useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const [token, setToken] = useToken();
    var initialColors = false;
    var fromAI = false;
    var name = "";
    if(location.state) {
        initialColors = location.state.colors;
        fromAI = location.state.fromAI;
        name = location.state.name;
    }
    const [colors, setColors] = useState( initialColors ? initialColors.map((i) => {return i.hex}) : []);
    const [universalFont, setUniversalFont] = useState(true);
    const [themeName, setThemeName] = useState(name);
    const [pageBackground, setPageBackground] = useState("#FFFFFF");
    const [saveError, setSaveError] = useState(false);
    const [font, setFont] = useState("Segoe UI");
    const [pageBackgroundTransparency, setPageBackgroundTransparency] = useState(0);
    const [tags, setTags] = useState([]);
    const [textColor, setTextColor] = useState("#404040");
    const [titleFonts, setTitleFonts] = useReducer(fontReducer, {
        fontSize: 12,
        fontFace: "Segoe (Bold)",
        color: "#404040"
    })
    const [headerFonts, setHeaderFonts] = useReducer(fontReducer, {
        fontSize: 12,
        fontFace: "Segoe UI",
        color: "#404040"
    })
    const [labelFonts, setLabelFonts] = useReducer(fontReducer, {
        fontSize: 12,
        fontFace: "Segoe UI",
        color: "#404040"
    })
    const [calloutFonts, setCalloutFonts] = useReducer(fontReducer, {
        fontSize: 12,
        fontFace: "Segoe UI",
        color: "#404040"
    })

    const appInsights = getAppInsights();
    appInsights.loadAppInsights();
    
    useEffect(() => {
        appInsights.trackPageView();
    }, [appInsights])

    const selectableFonts = [
        'Ariel',
        'Ariel Black',
        'Calibri',
        'Cambria',
        'Cambria Math',
        'Candara',
        'Comic Sans MS',
        'Consolas',
        'Constantia',
        'Corbel',
        'Courier New',
        'Georgia',
        'Lucida Sans Unicode',
        'Segoe (Bold)',
        'Segoe UI',
        'Segoe UI Light',
        'Tahoma',
        'Times New Roman',
        'Trebuchet MS',
        'Verdana'
    ]

    const crumbs = [
        {
            text: "Home",
            url: "/"
        },
        {
            text: "Create manually",
            url: "/create"
        }
    ]

    function tagsCallback(tags) {
        setTags(tags);
    }

    if (user) {
        const {id, email, info} = user;
    }

    function handleColorChange(index, event) {
        var mycolors = colors.slice()
        mycolors[index] = event.target.value;
        setColors(mycolors);
    }

    const handleAddClick = () => {
        var mycolors = colors.slice()
        mycolors.push("#FFFFFF")
        setColors(mycolors)
    }

    async function handleColorDelete(index, event) {
        colorCardDeleteAnimate(event);
        setTimeout(() => {
            var mycolors = colors.slice()
            mycolors.splice(index, 1)
            setColors(mycolors)
        }, 300);
    }

    useEffect(() => {
        var cards = document.querySelectorAll(".color-card");
        if (cards.length > 0){
            cards.forEach(element => {
                element.classList.remove('color-card-deleted')
            });
        }
        
    }, [colors])

    function delay(milliseconds){
        var resolve = () => {
            
        }
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    }

    function handleColorReorder(oldIndex, newIndex) {
        if (newIndex < 0){return}
        if (newIndex > colors.length){return}
        var arr = colors.slice();
        var element = arr[oldIndex];
        arr.splice(oldIndex, 1);
        arr.splice(newIndex, 0, element);
        setColors(arr);
    }

    const rotateIcon = () => {
        let icon = document.getElementById("add-color-icon");
        icon.classList.toggle("add-icon-rotated");
    }

    function handleFontClick() {
        if (document.getElementById("universalTextCheck").checked) {
            setUniversalFont(false)
        }
        else {
            setUniversalFont(true)
        }
    }

    const  handleSaveTheme = async () =>  {
        let saveBody = {
            userId: user.id,
            name: themeName,
            pageBackground: pageBackground,
            colors: colors,
            universalFont: universalFont,
            font: font,
            backgroundTransparency: pageBackgroundTransparency,
            tags: tags,
            textColor: textColor,
            fromImage: initialColors && !fromAI ? true : false,
            fromAi: fromAI,
            advancedFonts: {
                titles: titleFonts,
                headers: headerFonts,
                labels: labelFonts,
                callouts: calloutFonts
            }
        }
        var headers = {
            Authorization: `Bearer ${token}`
        }
        const response = await axios.post('/themes/new', saveBody, {headers: headers});
        if (response.status === 200){
            const submittedId = response.data.id;
            navigate({
                pathname: `/themes/view/${submittedId}`
            })
        }
        else {
            setSaveError(true);
        }
    }

    const handleDownloadClicked = async () => {
        appInsights.trackEvent(
            {
            "name": "downloadThemeEvent",
            "properties": {
                "source": "createPage"
            }
            }
        )

        console.log('tried to download.')

        let downloadBody = {
            name: themeName,
            pageBackground: pageBackground,
            colors: colors,
            universalFont: universalFont,
            font: font,
            backgroundTransparency: pageBackgroundTransparency,
            textColor: textColor, 
            advancedFonts: {
                titles: titleFonts,
                headers: headerFonts,
                labels: labelFonts,
                callouts: calloutFonts
            }
        }
        let jsonData = compileThemeReducer(downloadBody);
        var fileName = `${downloadBody.name}.json`;
        let fileToSave = new Blob([jsonData], {
            type: 'application/json'
        });
        FileSaver.saveAs(fileToSave, fileName)
        console.log("finished downloading!")
    }

    const font_strike_styles = {
        '> span:before' : {
            color: "white",
        },
        '> span:after' : {
            color: "white"
        }
    }
    
    return (
        <div className="container-fluid" style={{"paddingTop": "70px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
            <Breadcrumb crumbs={crumbs}></Breadcrumb>
            <div className='container'>
                <h3 className='create-title'>
                    Create a new Power BI Theme
                </h3>
            </div>
            <div className='section-darkgray'>
                <div className='container'>
                    <div className="strike strike-white">
                        <span className='strike-text-yellow'>General</span>
                    </div>
                    <div  className='create-container' >
                        <label style={{"textAlign": "left", "color": "#ececec"}}>Theme Name</label>
                        <input onChange={(e) => setThemeName(e.target.value)} className='theme-name-input' defaultValue={name}></input>
                    </div>
                    <div className='create-container'>
                        { 
                            <TagEditor tags={tags} callback={tagsCallback}></TagEditor>
                            
                        }
                    </div>
                </div>
            </div>
            <div className='section-darkgray'>
                <div className='container'>
                    <div className="strike strike-white">
                        <span className='strike-text-yellow'>Page Background</span>
                    </div>
                    <div className='create-container'>
                        
                        <Form style={{"display" : "flex", "alignItems": "center"}}>
                            <div style={{"display": "flex", "alignItems": "flex-start", "justifyItems": "flex-start", 'flexDirection': "column"}}>
                                <label style={{"textAlign": "left", "color": "#ECECEC"}}>{"Select Color"}</label>
                                <Form.Control onChange={(e) => setPageBackground(e.target.value)} style={{"background": "#727272", "border": "none", "width": "150px", "cursor": "pointer", "height": "50px", "boxShadow": "0 0  3px -1px rgb(64, 64, 64)"}} type="color" defaultValue={pageBackground} title={"Choose Color"}>
                                </Form.Control>
                            </div>
                            <div style={{"paddingLeft": "20px", "alignItems": "flex-start", "display": "flex", "flexDirection": "column", "justifyItems": "flex-start" }}>
                                <label style={{"textAlign": "left", "color": "#ECECEC"}}>{"Transparency (%)"}</label>
                                <Form.Control onChange={(e) => setPageBackgroundTransparency(e.target.value)} defaultValue={pageBackgroundTransparency} min="0" max="100" type='number' style={{ "width": "100px", "background": "#727272", "color": "white", "height": "50px", "boxShadow": "0 0  3px -1px rgb(64, 64, 64)"}}></Form.Control>
                                {
                                    pageBackgroundTransparency>=0 && pageBackgroundTransparency <=100 ? 
                                    <></>
                                    :
                                    <small style={{"textAlign": "left"}}>{"Transparency must be set to an integer from 0 and 100."}</small>
                                }
                            </div>                            
                        </Form>
                        {/* <small style={{"textAlign": "left"}}>{pageBackground}</small> */}
                    </div>
                </div>
            </div>
            <div className='section-darkgray'>
                <div className='container'>
                    <div className="strike strike-white">
                        <span className='strike-text-yellow'>Theme Colors</span>
                    </div>
                    <div  className='color-card-container'>
                        {colors.map((color, key) => {
                            var cardId = v4();
                            return(
                                    <span style={{"margin": "3px"}} key={key}  id={key}>
                                        <div id={`cc${cardId}`} className='color-card'>
                                            {/* <small style={{"fontSize": "x-small", "width": "100%", "justify": "left"}}>{color}</small> */}
                                            <Form.Control key={key} onChange={(event) => handleColorChange(key, event)} style={{"background": color}} className='color-card-selector' type="color" value={color} title={"Choose Color"}>
                                            </Form.Control>
                                            
                                            <div className='color-card-controls'>
                                                {key!==0? <i onClick={() => handleColorReorder(key, key-1)} className='fi fi-rr-angle-left color-card-control-icon'></i> : <></>}  
                                                {key<colors.length-1? <i onClick={() => handleColorReorder(key, key+1)} className='fi fi-rr-angle-right color-card-control-icon'></i> : <></>}
                                                <i id={cardId} onClick={(event) => handleColorDelete(key, event)} className='fi fi-rr-trash color-card-control-icon'></i>
                                            </div>
                                        
                                        </div>   
                                    </span>  
                                    
                            )
                        })}
                        <span>
                            <button onClick={handleAddClick} onMouseEnter={rotateIcon} onMouseLeave={rotateIcon} className='add-color-button'>
                                <i id="add-color-icon" className='fi fi-rr-plus icon-centered add-icon' ></i>
                            </button>
                        </span>
                    </div>
                    {
                        colors.length > 1 ? 
                        <div style={{"width": "100%", "display": "flex", "padding": ".5rem"}}>
                            <button className='dark-mode-secondary-button' onClick={() => setColors(colors.slice().reverse())}>Reverse Order of Colors</button>
                        </div> : <></>
                    }
                    
                </div>

            </div>
            <div className='section-darkgray'>
                <div className='container'>
                    <div className="strike strike-white">
                        <span className='strike-text-yellow'>Fonts</span>
                    </div>
                    <div className='create-container'>
                        <form style={{"display": "flex", "alignItems": "left", "flexDirection": "column", "paddingLeft":"0px"}}>
                                <div style={{"display": "flex", "alignItems":"left", "paddingLeft": "23px", "paddingBottom": ".5rem"}}>
                                    <input id={"universalTextCheck"} className='form-check-input checkbox-rounded font-check'  defaultChecked={!universalFont} onClick={() => handleFontClick()} type={"checkbox"}></input>
                                    <label className="form-check-label font-check-label" for="universalTextCheck">{String("Customize advanced font options") }</label>
                                </div>
                            
                            {
                                universalFont?
                                    <div style={{"display": "flex", "flexDirection": "column", "paddingTop": "5px"}}>
                                        <label style={{"textAlign": "left", "color": "white"}}>Font Name</label>
                                        {/* <input className='font-input' defaultValue={font} onChange={(e) => setFont(e.target.value)} ></input> */}
                                        <select className='font-input' defaultValue={font} onChange={(e) => setFont(e.target.value)}>
                                            {
                                                selectableFonts.map((font, index) => {
                                                    return(
                                                        <option key={index} value={font}>{font}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <div style={{"display": "flex", "alignItems": "flex-start", "justifyItems": "flex-start", 'flexDirection': "column", "paddingTop": "10px"}}>
                                            <label style={{"textAlign": "left", "color": "white"}}>{"Text Color"}</label>
                                            <Form.Control onChange={(e) => setTextColor(e.target.value)} className='color-selector-small' type="color" defaultValue={textColor} title={"Choose Color"}>
                                            </Form.Control>
                                        </div>
                                    </div>
                                    :
                                    <div style={{"display": "flex", "flexDirection": "column", "paddingTop": "5px"}}>
                                        <div className='font-configuration-container'>
                                            <h3 className='font-configuration-header'>Titles</h3>
                                            <div className='font-configuration-group'>
                                                <div className='font-form-group'>
                                                    <label className='font-form-label'>
                                                        Font Face
                                                    </label>
                                                    <select className='advanced-font-face-select' defaultValue={titleFonts.fontFace} onChange={(e) => setTitleFonts({type: "fontFace", value: e.target.value})}>
                                                    {
                                                        selectableFonts.map((font, index) => {
                                                            return(
                                                                <option key={index} value={font}>{font}</option>
                                                            )
                                                        })
                                                    }
                                                    </select>
                                                </div>
                                                <div className='font-form-group'>
                                                    <label className='font-form-label'>
                                                        Font Size
                                                    </label>
                                                    <Form.Control onChange={(e) => setTitleFonts({type: "fontSize", value: parseInt(e.target.value)})} defaultValue={titleFonts.fontSize} min="0" max="100" type='number' style={{"width": "100px", "height": "40px", "boxShadow": "0 0  3px -1px rgb(64, 64, 64)"}}></Form.Control>
                                                </div>
                                                <div className='font-form-group'>
                                                    <label className='font-form-label'>
                                                        Font Color
                                                    </label>
                                                    <Form.Control onChange={(e) => setTitleFonts({type: "color", value: e.target.value})} className='color-selector-small-short' type="color" defaultValue={titleFonts.color} title={"Choose Color"}>
                                                    </Form.Control>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='font-configuration-container'>
                                            <h3 className='font-configuration-header'>Headers</h3>
                                            <div className='font-configuration-group'>
                                                <div className='font-form-group'>
                                                    <label className='font-form-label'>
                                                        Font Face
                                                    </label>
                                                    <select className='advanced-font-face-select' defaultValue={headerFonts.fontFace} onChange={(e) => setHeaderFonts({type: "fontFace", value: e.target.value})}>
                                                    {
                                                        selectableFonts.map((font, index) => {
                                                            return(
                                                                <option key={index} value={font}>{font}</option>
                                                            )
                                                        })
                                                    }
                                                    </select>
                                                </div>
                                                <div className='font-form-group'>
                                                    <label className='font-form-label'>
                                                        Font Size
                                                    </label>
                                                    <Form.Control onChange={(e) => setHeaderFonts({type: "fontSize", value: parseInt(e.target.value)})} defaultValue={headerFonts.fontSize} min="0" max="100" type='number' style={{"width": "100px", "height": "40px", "boxShadow": "0 0  3px -1px rgb(64, 64, 64)"}}></Form.Control>
                                                </div>
                                                <div className='font-form-group'>
                                                    <label className='font-form-label'>
                                                        Font Color
                                                    </label>
                                                    <Form.Control onChange={(e) => setHeaderFonts({type: "color", value: e.target.value})} className='color-selector-small-short' type="color" defaultValue={headerFonts.color} title={"Choose Color"}>
                                                    </Form.Control>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='font-configuration-container'>
                                            <h3 className='font-configuration-header'>Labels</h3>
                                            <div className='font-configuration-group'>
                                                <div className='font-form-group'>
                                                    <label className='font-form-label'>
                                                        Font Face
                                                    </label>
                                                    <select className='advanced-font-face-select' defaultValue={labelFonts.fontFace} onChange={(e) => setLabelFonts({type: "fontFace", value: e.target.value})}>
                                                    {
                                                        selectableFonts.map((font, index) => {
                                                            return(
                                                                <option key={index} value={font}>{font}</option>
                                                            )
                                                        })
                                                    }
                                                    </select>
                                                </div>
                                                <div className='font-form-group'>
                                                    <label className='font-form-label'>
                                                        Font Size
                                                    </label>
                                                    <Form.Control onChange={(e) => setLabelFonts({type: "fontSize", value: parseInt(e.target.value)})} defaultValue={labelFonts.fontSize} min="0" max="100" type='number' style={{"width": "100px", "height": "40px", "boxShadow": "0 0  3px -1px rgb(64, 64, 64)"}}></Form.Control>
                                                </div>
                                                <div className='font-form-group'>
                                                    <label className='font-form-label'>
                                                        Font Color
                                                    </label>
                                                    <Form.Control onChange={(e) => setLabelFonts({type: "color", value: e.target.value})} className='color-selector-small-short' type="color" defaultValue={labelFonts.color} title={"Choose Color"}>
                                                    </Form.Control>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='font-configuration-container'>
                                            <h3 className='font-configuration-header'>Callouts</h3>
                                            <div className='font-configuration-group'>
                                                <div className='font-form-group'>
                                                    <label className='font-form-label'>
                                                        Font Face
                                                    </label>
                                                    <select className='advanced-font-face-select' defaultValue={calloutFonts.fontFace} onChange={(e) => setCalloutFonts({type: "fontFace", value: e.target.value})}>
                                                    {
                                                        selectableFonts.map((font, index) => {
                                                            return(
                                                                <option key={index} value={font}>{font}</option>
                                                            )
                                                        })
                                                    }
                                                    </select>
                                                </div>
                                                <div className='font-form-group'>
                                                    <label className='font-form-label'>
                                                        Font Size
                                                    </label>
                                                    <Form.Control onChange={(e) => setCalloutFonts({type: "fontSize", value: parseInt(e.target.value)})} defaultValue={calloutFonts.fontSize} min="0" max="100" type='number' style={{"width": "100px", "height": "40px", "boxShadow": "0 0  3px -1px rgb(64, 64, 64)"}}></Form.Control>
                                                </div>
                                                <div className='font-form-group'>
                                                    <label className='font-form-label'>
                                                        Font Color
                                                    </label>
                                                    <Form.Control onChange={(e) => setCalloutFonts({type: "color", value: e.target.value})} className='color-selector-small-short' type="color" defaultValue={calloutFonts.color} title={"Choose Color"}>
                                                    </Form.Control>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                            
                        </form>
                    </div>
                </div>
                
            </div>
            <div className='section-darkgray'>
                <div className='container' style={{"display": "flex", "flexDirection": "row", "justifyContent": "flex-end", "flexWrap": "wrap"}}>
                    {user?
                    <>
                    <Link to='/'>
                        <button className='discard-theme-button'>Discard</button>
                    </Link>
                    <button onClick={() => handleDownloadClicked()} className='download-theme-button'>Download Theme</button>
                    <button onClick={handleSaveTheme} className='save-theme-button'>Save Theme</button>
                    </>
                    :
                    <button onClick={() => handleDownloadClicked()} className='save-theme-button'>Download Theme</button>
                    }
                    
                </div>
            </div>
            
        </div>
    )
}
