import './TagEditor.css';
import './css/shared.css';
import { useState } from 'react';
import '../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/icons/uicons-bold-rounded/css/uicons-bold-rounded.css';


export default function TagEditor(props) {
    const tagsProp = props.tags;
    const callback = props.callback;
    const [editMode, setEditMode] = useState(false);

    const [tags, setTags] = useState(tagsProp);
    const [editTagValue, setEditTagValue] = useState('')

    const handleCallback = (tprop) => {
        callback(tprop);
    }

    const handleAddTag = async () => {
        if (tags.length >= 5){
            alert("You can't have more than 5 tags. Please delete a tag to add a new one.");
            setEditMode(false);
            return;
        }
        var tagvalue = editTagValue;
        if (tagvalue.length > 1) {
            var mytags = tags.slice();
            mytags.push(tagvalue);
            await setTags(mytags);
            setEditMode(false);
            handleCallback(mytags);
            setEditTagValue("");
        }
        else {
            setEditMode(false)
        }  
    }

    const handleRemoveTag = (index) => {
        var mytags = tags.slice()
        mytags.splice(index, 1)
        setTags(mytags);
        setEditMode(false);
        handleCallback(mytags);
    }

    return (
        <div className='edit-tag-container'>
            {
        tags.map((tag, index) => {
            return(
                <div key={'tag-' + tag} className='tag-div'>
                    <p className='tag-text'>{tag}</p>
                    <i className="fi fi-rr-cross-circle tag-icon icon-centered" onClick={() => handleRemoveTag(index)}></i>
                </div>
            )
        })}
        {
            editMode ?
            <div className='tag-div' style={{"display": "flex", "flexDirection": "row", 'alignItems': 'center', "justifyItems": "center"}}>
                <input className='tag-edit-input' value={ editTagValue } onChange={(e) => setEditTagValue(e.target.value)}></input>
                <i className="fi fi-br-cross tag-edit-icon" style={{'color': "#f75c69"}} onClick ={() => setEditMode(false)}></i>
                <i className="fi fi-br-check tag-edit-icon" style={{'color': "#50f2a4"}} onClick = {() => handleAddTag()}></i>
            </div>
            :
            <></>
        }
        {
            !editMode ?
                <div onClick={() => setEditMode(true)} className='tag-add-button tag-div'>
                    <i className="fi fi-rr-add tag-add-button-icon"></i>
                </div>
            : <></>
        }
        
        </div>
    )  
}