import './ThemePreviewCard.css';
import { Link } from 'react-router-dom';
import '../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/icons/uicons-solid-rounded/css/uicons-solid-rounded.css';

export default function ThemePreviewCard(props) {
    const theme = props.theme;

    const type = props.type;

    function CardTag(props){
        return(
            <div className='card-tag'>
                <span>{props.value}</span>
            </div>
        )
    }

    return(
        <Link to={`/themes/view/${theme._id}`} style={{ textDecoration: 'none' }}>
            <div className='theme-preview-card p-3'>
                <div className='theme-preview-card-palette-container'>
                    {theme.colors.map((color, index)=> {
                        return(
                            <div id={`${type}-theme-preview-card-${theme._id}`} key={index} className='theme-preview-card-palette-cell' style={{"background": color}}>

                            </div>
                        )
                    })}
                </div>
                <h6 className='theme-preview-card-name'>{`${theme.name}${'\xa0'.repeat(1)}`} 
                    {
                        !theme.isShared ? 
                        <i className="fi fi-rr-eye-crossed theme-preview-card-lock icon-centered"></i>
                        :
                        <></>
                    }
                </h6>
                <div style={{"width": "80%"}}>
                    <h6 className='theme-preview-card-detail'><i className="fi fi-rr-download theme-preview-card-detail-icon icon-centered"></i>{`   ${theme.downloadCount ? theme.downloadCount : 0} download${theme.downloadCount ? (theme.downloadCount === 1 ? "" : 's') : 's'}`}</h6>
                </div>
                <div style={{"width": "80%", "display": "flex", "flexWrap": "wrap"}}>
                    {
                        theme.tags ? 
                        theme.tags.map((value, key) => {
                            return(
                                <CardTag value={value}/>
                            )
                            
                        })
                        :
                        <></>
                    }
                </div>
                
                {/* <i id={`${type}-theme-preview-icon-${theme._id}`} className='fi fi-rr-arrow-right theme-preview-arrow'></i> */}
            </div>
        </Link>
    )
}