import { useState, useEffect } from "react";
import { useToken } from "./useToken.js";
import {Buffer} from 'buffer';

export const useUser = () => {
    const [token] = useToken();
    const getPayloadFromToken = token => {
        const encodedPayload = token.split('.')[1];
        var buff = Buffer.from(encodedPayload, 'base64');
        return JSON.parse(buff.toString('ascii'));
    }

    const [user, setUser] = useState(() => {
        if (!token) return null;
        return getPayloadFromToken(token);
    })

    useEffect(() => {
        if (!token) {
            setUser(null);
        }
        else {
            setUser(getPayloadFromToken(token));
        }
    }, [token]);

    return user;
}