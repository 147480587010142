import './Explore.css';
import { useUser } from '../auth/useUser';
import '../components/css/shared.css';
import { useToken } from '../auth/useToken';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ThemeLink from '../components/ThemeLink';
import '../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/icons/uicons-solid-rounded/css/uicons-solid-rounded.css';
import ThemePreview from '../components/ThemePreview';
import ThemePreviewCard from '../components/ThemePreviewCard'
import ReactPaginate from 'react-paginate';
import '../components/css/pagination.css';
import getAppInsights from '../utils/logging';
import Breadcrumb from '../components/Breadcrumb.js';


export default function Browse() { 
    const user = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const [,setToken] = useToken();
    const [recentThemes, setRecentThemes] = useState();
    const [recentThemesSplit, setRecentThemesSplit] = useState();
    const [currentItems, setCurrentItems] = useState();
    const [currentRange, setCurrentRange] = useState();
    const [currentIndex, setCurrentIndex] = useState();

    const itemsPerPage = 15;

    const crumbs = [
        {
            text: "Home",
            url: "/"
        },
        {
            text: "Browse",
            url: "/browse"
        }
    ]

    const appInsights = getAppInsights();
    appInsights.loadAppInsights();
    
    useEffect(() => {
        appInsights.trackPageView();
    }, [appInsights])

    useEffect(() => {
        if(recentThemes) {
            const perChunk = itemsPerPage;
            const result = [];
            for (let i = 0; i < recentThemes.length; i += perChunk) {
                const chunk = recentThemes.slice(i, i+perChunk);
                result.push(chunk)
            }
            setRecentThemesSplit(result);
            setCurrentItems(result[0])
        }
    }, [recentThemes])

    const fetchThemes = async () => {
        const response = await axios.post('/themes/get/recent', {limit: 1000});
        setRecentThemes(response.data);
        setCurrentIndex(0);
        setCurrentRange(`Showing ${1}-${itemsPerPage< response.data.length ? itemsPerPage : response.data.length-1} of ${response.data.length}`)
    }

    useEffect(() => {
        fetchThemes();
    }, [])


    function Items(props) {
        return (
            
            <div className=' px-4 preview-container'>
                <div className='row gx-5' style={{"width": "100%"}}>
                {
                    props.themes ? 
                    props.themes.map((theme, index) => {
                        return (
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                <ThemePreviewCard key={index} theme={theme} type='search'/>
                            </div>
                            
                        )
                    })
                    :
                    <></>
                }
                </div>
            </div>
        )
    }

    useEffect(()=>{
        if(recentThemes && currentItems) {
            setCurrentRange(`Showing ${itemsPerPage*currentIndex + 1}-${(itemsPerPage*currentIndex+1) + currentItems.length-1} of ${recentThemes.length}`)
        }
        
    }, [currentIndex, currentItems, recentThemes])

    const handlePageClick = async  (event) => {
        const pageIndex = event.selected;
        setCurrentItems(recentThemesSplit[pageIndex]);
        setCurrentIndex(pageIndex)  
    }

    const handlePopularClick = async (event) => {
        var themes = recentThemes.slice();
        themes.sort((a, b) => {
            var ac = a.downloadCount ?? 0;
            var bc = b.downloadCount ?? 0;
            return parseInt(bc) - parseInt(ac)
        });
        setRecentThemes(themes);
        setCurrentIndex(0);
    }

    const handleRecentClick = async (event) => {
        var themes = recentThemes.slice();
        themes.sort((a, b) => {
            var ac = new Date(a.createDate);
            var bc = new Date(b.createDate);
            return bc-ac;
        });
        setRecentThemes(themes);
        setCurrentIndex(0);
    }
    const handleOldestClick = async (event) => {
        var themes = recentThemes.slice();
        themes.sort((a, b) => {
            var ac = new Date(a.createDate);
            var bc = new Date(b.createDate);
            return ac-bc;
        });
        setRecentThemes(themes);
        setCurrentIndex(0);
    }

    return (
        <div className="container-fluid" style={{"paddingTop": "70px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
            <Breadcrumb crumbs={crumbs}></Breadcrumb>
            <div className='section-darkgray'>
                <div className='container '>
                    <div className="strike strike-white">
                        <span style={{"color": "#ECECEC", "fontSize": "25px"}}>Browse Themes</span>
                    </div>
                </div>
            </div>
            
            <div className='container' >
            <ul className="nav nav-pills" style={{"paddingTop": "2rem"}}>
                <li className="nav-item ">
                    <a onClick={handleRecentClick} className="nav-link browse-pill active" data-toggle="pill" >Most Recent</a>
                </li>
                <li className="nav-item">
                    <a onClick={handlePopularClick} className="nav-link browse-pill" data-toggle="pill">Popular</a>
                </li>
                <li className="nav-item">
                    <a onClick={handleOldestClick} className="nav-link browse-pill" data-toggle="pill">Oldest</a>
                </li>
            </ul>

                <div style={{"display": "flex", "flexDirection": "column", "justifyItems": "flex-start", "paddingTop": "15px"}}>
                    {
                        recentThemes ? <h3 className='search-header'>{currentRange}</h3> : <></>
                    }
                    
                </div>
                {
                    recentThemesSplit ?
                    <div style={{"minHeight": "calc(100vh - 400px)", "display": "flex", "flexDirection": "column", "justifyContent": "space-between"}}>
                        
                    <Items themes={currentItems}></Items>
                    <ReactPaginate
                    className='search-pagination' 
                    breakLabel="..."
                    nextLabel= {<i className="fi fi-rr-arrow-right pagination-icon"></i>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={recentThemesSplit.length}
                    previousLabel= {<i className="fi fi-rr-arrow-left pagination-icon"></i>}
                    forcePage={currentIndex}
                    renderOnZeroPageCount={null}/>
                    </div>
                    :
                    <div className="spinner-grow" role="status" style={{"width": "2rem", "height": "2rem"}}>
                            <span className="sr-only">Loading...</span>
                    </div>

                }
                
                
            </div>
            
            
            
        </div>
    )
}
