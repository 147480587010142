import { useEffect, useState } from 'react';
import './css/shared.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './Login.css';
import axios from 'axios';
import { useToken } from '../auth/useToken';
import Modal  from 'react-modal'

function ResetPasswordLanding() {
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
    const navigate = useNavigate();
    const [token, setToken] = useToken();
    const { resetPasswordCode } = useParams();
    const [isSuccess, setIsSuccess] = useState();
    const [isFailure, setIsFailure] = useState();


    const handleResetPassword = async () => {
        try {
            await axios.put(`/users/${resetPasswordCode}/reset-password`, { newPassword: passwordValue});
            setIsSuccess(true);
        } catch (e){
            setIsFailure(true)
        }
    }

    function Failure() {
        return (
            <div className='container'>
                <div style={{"display": "flex", "justifyContent": "space-between", "width": "100%", "flexDirection": "row", "alignItems": "center"}}>
                    <h6 style={{"fontFamily": "Segoe UI", "fontSize": "1.15rem", "fontWeight": "400", "margin": "0", 'textAlign': "left"}}>{`We couldn't reset your password. Please resend the reset email from the login page to try again.`}</h6>
                    <Link to={'/'}>
                        <button className='black-primary-button'>Go to Home</button>
                    </Link>
                </div>
            </div>
        )
    }

    function Success() {
        return (
            <div className='container'>
                <div style={{"display": "flex", "justifyContent": "space-between", "width": "100%", "flexDirection": "row", "alignItems": "center"}}>
                    <h6 style={{"fontFamily": "Segoe UI", "fontSize": "1.15rem", "fontWeight": "400", "margin": "0", 'textAlign': "left"}}>{`Your password has been successfully reset.`}</h6>
                    <Link to={'/login'}>
                        <button className='black-primary-button'>Go to Login</button>
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <div className="container-fluid" style={{"paddingTop": "100px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
            <div className='section-lightgray'>
                {
                    isSuccess || isFailure ? 
                        isSuccess? 
                        <Success></Success>
                        :
                        <Failure></Failure>
                    :
                    <div className='container'>
                        <div style={{"paddingBottom": "15px"}}>
                        <div className="strike">
                            <span>Reset Your Password</span>
                        </div>
                        {errorMessage && <div>errorMessage</div>}
                        </div>
                        <form style={{"display": "flex", "flexDirection": "column", "alignItems": "center"}}>
                            
                            <div className="form-group center-block login-form-group">
                                <label className="login-label" htmlFor="passwordinput">New Password</label>
                                <input onChange={(e) => setPasswordValue(e.target.value)} type="password" width="25%" id='passwordinput' name='name' className='form-control password-input' placeholder='enter new password'></input>
                            </div>
                            <div className="form-group center-block login-form-group">
                                <label className="login-label" htmlFor="confirmpasswordinput">Confirm New Password</label>
                                <input onChange={(e) => setConfirmPasswordValue(e.target.value)} type="password" width="25%" id='confirmpasswordinput' name='name' className='form-control password-input' placeholder='confirm new password'></input>
                            </div>
                            <div className="form-group login-button-group">
                                <button disabled={!confirmPasswordValue || !passwordValue} onClick={() => handleResetPassword()} type='button'  className="login-button">
                                    Reset Password
                                </button>
                            </div>
                        </form>
                    </div>
                    
                }
            </div>            
        </div>
    )
}

export default ResetPasswordLanding;