import { useState, useEffect } from 'react';
import './css/shared.css';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './Login.css';
import axios from 'axios';
import { useToken } from '../auth/useToken';
import Modal  from 'react-modal';
import SignupTeaser from './SignupTeaser';
import '../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/icons/uicons-solid-rounded/css/uicons-solid-rounded.css';
import '../external/icons/uicons-brands/css/uicons-brands.css';
import getAppInsights from '../utils/logging';

function Login() {
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [token, setToken] = useToken();
    const [resetIsOpen, setResetIsOpen] = useState(false);
    const [resetEmailSent, setResetEmailSent] = useState(false);
    const [resetEmail, setResetEmail] = useState("");
    const [googleOauthUrl, setGoogleOauthUrl] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const oauthToken = searchParams.get('token');

    const appInsights = getAppInsights();
    appInsights.loadAppInsights();
    
    useEffect(() => {
        appInsights.trackPageView();
    }, [appInsights])

    useEffect(() => {
        if (oauthToken) {
            console.log('found the token')
            setToken(oauthToken);
            navigate('/');
            window.location.reload(false);
        }
    }, [oauthToken, setToken, navigate])

    const modalStyles={
        content: {
            top: '25%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -0%)',
            borderRadius: "15px",
            backgroundColor: "#404040",
            boxShadow: "0 0 5px 0 rgb(64, 64, 64)",
            border: "none",
            maxWidth: "90%"
          }        
    }

    useEffect(() => {
        const loadOauthUrl = async () => {
            try {
                const response = await axios.get('/auth/google/url');
                const url = response.data;
                setGoogleOauthUrl(url.url);
            }catch(e){
                console.log(e)
            }
        }
        loadOauthUrl();
    }, []);

    const onLogInClicked = async () => {
        try{
            const response = await  axios.post('/api/login', {
                email: email,
                password: passwordValue
            });
            const responseCode = response.status;
            if (responseCode === 401) {
                setErrorMessage("Sign in failed - please try again.");
                return
            }
            const { token } = response.data;
            setToken(token);
            if(location.state){
                navigate(location.state.from);
            }
            else {
                navigate('/')
            }
            window.location.reload(false);
            appInsights.trackEvent(
            {
                "name": "loginEvent",
                "properties": {
                    "source": "loginPage"
                }
            })
        }
        catch (e) {
            console.log(e)
            setErrorMessage("Sign in failed - please try again.")
        }
        
    }

    function handleResetClicked() {
        const response = axios.put(`/users/forgotpassword/${resetEmail}`);
        setResetEmailSent(true);
    }

    function closeResetModal() {
        setResetIsOpen(false);
        setResetEmailSent(false);
    }

    return (
        <div className="container-fluid" style={{"paddingTop": "100px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
            <div className='section-darkgray'>
                <div className="container">
                    <div style={{"paddingBottom": "15px"}}>
                        <div className="strike strike-white">
                            <span style={{"color": "#ECECEC"}}>Sign In to Power Palette</span>
                        </div>
                        {errorMessage && <div>
                            <span className='login-error-message'>{errorMessage}</span>
                            </div>}
                    </div>
                    <form style={{"display": "flex", "flexDirection": "column", "alignItems": "center"}}>
                        <div className="form-group center-block login-form-group">
                            <label className="login-label" htmlFor="emailinput">Email Address</label>
                            <input onChange={(e) => setEmail(e.target.value.toString().toLowerCase())} width="25%" id='emailinput' name='name' className='form-control default-input login-email-input' placeholder='enter email'></input>
                        </div>
                        <div className="form-group center-block login-form-group">
                            <label className="login-label" htmlFor="passwordinput">Password</label>
                            <input onChange={(e) => setPasswordValue(e.target.value)} type="password" width="25%" id='passwordinput' name='name' className='form-control default-input password-input' placeholder='enter password'></input>
                        </div>
                        <div className="form-group login-button-group">
                            <button disabled={!email || !passwordValue} onClick={onLogInClicked} type='button'  className="yellow-default-button-primary">
                                Login
                            </button>
                            <p style={{'marginBottom': "1px"}}><span className="signup-link">Don't have an account?  </span>
                                <span>
                                    <Link to={"/signup"}>
                                        <span className="signup-link" href="#">Sign Up</span>
                                    </Link>
                                </span>
                            </p>
                            <p><span className="signup-link">Need help signing in?  </span>
                                <span onClick={() => setResetIsOpen(true)}>
                                        <a className="signup-link" href='#'>Forgot Password</a>
                                </span>
                            </p>
                        </div>
                        {
                            googleOauthUrl ?  
                            <button type='reset' onClick={() => {window.location.href = googleOauthUrl}} className='dark-mode-secondary-button' >{`Sign in with Google${'\xa0'.repeat(2)}`}<i class="fi fi-brands-google icon-centered"></i></button>
                            :<></>
                        }
                        <small style={{"color": "#ECECEC"}}>By using this site you agree that you have read and will abide by our <a href='/terms'>terms and conditions</a>.</small>
                    </form>
                    
                </div>
            </div>
            <div className='section-darkgray'>
                <div className='container'>
                    <SignupTeaser callout="Once you're signed in, you can:"/>
                </div>
            </div>
            <Modal style={modalStyles} isOpen={resetIsOpen} onRequestClose={() => {setResetIsOpen(false)}} ariaHideApp={false} backgroundColor="black" centered contentLabel="Reset Password">
                <i className="fi fi-rr-cross-circle reset-modal-close-icon" onClick={() => closeResetModal()}></i>
                {
                    !resetEmailSent ? 
                    <>
                        <h1 className='reset-password-header'>Enter Email to send reset password link:</h1>
                        <div style={{"width": "100%"}}  className="form-group center-block login-form-group">
                            <input onChange={(e) => setResetEmail(e.target.value)} width="25%" id='resetemailinput' name='name' className='form-control login-email-input' placeholder='enter email'></input>
                            <button onClick={() => handleResetClicked()} className='yellow-default-button-primary' style={{'width': "max-content", "alignSelf": "flex-end", "marginTop":"20px"}}>Send Link</button>
                        </div>
                    </>
                    :
                    <>
                    <h1 className='reset-password-header'>{`A password reset email has been sent to ${resetEmail}.`}</h1>
                    <button onClick={() => closeResetModal() } className='yellow-default-button-primary' style={{'width': "max-content", "alignSelf": "flex-end", "marginTop":"20px"}}>Okay</button>
                    </>
                }
                
            </Modal>
            
        </div>
    )
}

export default Login;