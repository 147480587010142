import { useEffect, useState } from 'react';
import './css/shared.css';
import { Link, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import './EditProfile.css';
import axios from 'axios';
import { useToken } from '../auth/useToken';
import { useUser } from '../auth/useUser';
import Breadcrumb from './Breadcrumb';

function EditProfile() {
    const navigate = useNavigate();
    const location = useLocation();
    const initialState = location.state;
    const user = useUser();
    const [token, setToken] = useToken();
    const id = initialState.id;
    const [isUser, setIsUser] = useState(false); // true if the user being viewed is the user who is logged in
    const [userDetails, setUserDetails] = useState(null);
    const [email, setEmail] = useState('');
    const [firstNameValue, setFirtNameValue] = useState();
    const [lastNameValue, setLastNameValue] = useState();
    const [jobTitle, setJobTitle] = useState();
    const [company, setCompany] = useState();
    const [bio, setBio] = useState();
    const [error, setError] = useState(false);

    //update the isUser statevalue whenever the id or userdetails are updated
    useEffect(() => {
        if (id && user) {
            if (id == user.id) {
                setIsUser(true);
            }
            else {
                setIsUser(false);
            }
        }
        else {
            setIsUser(false);
        }
    },[id, userDetails])

    const crumbs = [
        {
            text: "Home",
            url: "/"
        },
        {
            text: "Profile",
            url: `/profile/view/${id}`
        },
        {
            text: "Edit",
            url: "/profile/edit"
        }
    ]

    //function that fetches user details for the id in the querystring, and populates the userDetails object
    const getUser = async () => {
        var headers = {
            Authorization: `Bearer ${token}`
        }
        var response = await axios.post('/users/getdetails', {userId: user.id}, {headers: headers});
        if (response.status == 200){
            setUserDetails(response.data);
        }
    }

    //call the getUser function
    useEffect(() => {
        getUser();
    }, [id])

    //broadcast userdetails to state
    useEffect(() => {
        if (userDetails) {
            setFirtNameValue(userDetails.firstName);
            setLastNameValue(userDetails.lastName);
            setEmail(userDetails.email);
            if (userDetails.info) {
                let info = userDetails.info;
                setBio(info.bio);
                setJobTitle(info.jobTitle);
                setCompany(info.company)
            }
        }
    }, [userDetails])

    const updateUser = async () => {
        var body = {
            email: email,
            firstName: firstNameValue,
            lastName: lastNameValue,
            info: {
                jobTitle : jobTitle,
                bio: bio,
                company: company
            }
        }
        var headers = {
            Authorization: `Bearer ${token}`
        }
        const response = await axios.post(`/users/edit/${user.id}`, {body : body}, {headers: headers});
        if (response.status == 200) {
            const { token } = response.data;
            setToken(token);
            navigate(`/profile/view/${id}`)
        }
        else{

        }
    }


    return (
        <div className="container-fluid" style={{"paddingTop": "70px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
            <Breadcrumb crumbs={crumbs}></Breadcrumb>
            <div className='section-darkgray'>
                {
                userDetails ? 
                <div className="container">
                    <div style={{"paddingBottom": "15px"}}>
                        <div className="strike strike-white">
                            <span className='strike-text-yellow'>Edit Profile Information</span>
                        </div>
                    </div>
                    <form style={{"display": "flex", "flexDirection": "column", "alignItems": "center"}}>
                        <div className="form-group center-block signup-form-group">
                            <label className="signup-label" htmlFor="firstNameInput">First Name</label>
                            <input defaultValue={userDetails.firstName} onChange={(e) => setFirtNameValue(e.target.value)} width="25%" id='firstNameInput' name='firstName' className='form-control default-input' placeholder='enter first name'></input>
                        </div>
                        <div className="form-group center-block signup-form-group">
                            <label className="signup-label" htmlFor="lastNameInput">Last Name</label>
                            <input defaultValue={userDetails.lastName} onChange={(e) => setLastNameValue(e.target.value)} width="25%" id='lastNameInput' name='firstName' className='form-control default-input' placeholder='enter last name'></input>
                        </div>
                        <div className="form-group center-block signup-form-group">
                            <label className="signup-label" htmlFor="emailinput">Email Address</label>
                            <input defaultValue={userDetails.email} onChange={(e) => setEmail(e.target.value)} width="25%" id='emailinput' name='email' className='form-control default-input' placeholder='enter email'></input>
                        </div>
                        <div className="form-group center-block signup-form-group">
                            <label className="signup-label" htmlFor="bioInput">Bio</label>
                            <textarea defaultValue={userDetails.info.bio} onChange={(e) => setBio(e.target.value)} rows={`4`} width="25%" id='bioInput' name='email' className='form-control default-input' placeholder='a short bio'></textarea>
                        </div>
                        <div className="form-group center-block signup-form-group">
                            <label className="signup-label" htmlFor="jobtitleinput">Job Title</label>
                            <input defaultValue={userDetails.info.jobTitle} onChange={(e) => setJobTitle(e.target.value)} width="25%" id='jobtitleinput' name='email' className='form-control default-input' placeholder='enter job title'></input>
                        </div>
                        <div className="form-group center-block signup-form-group">
                            <label className="signup-label" htmlFor="companyinput">Company</label>
                            <input defaultValue={userDetails.info.company} onChange={(e) => setCompany(e.target.value)} width="25%" id='companyinput' name='email' className='form-control default-input' placeholder='enter company'></input>
                        </div>
                        
                    </form>
                </div>
                :
                <></>
                }
            </div>
            <div className='section-darkgray'>
                <div className='container'>
                    <div className='button-group'>
                        <Link to={`/profile/view/${id}`}>
                            <button  className='dark-mode-secondary-button'>Discard</button>
                        </Link>
                        <button onClick={() => updateUser()} disabled={!firstNameValue || !lastNameValue || !email} className='yellow-default-button-primary'>Save Changes</button>
                    </div>
                    {
                        error ? <small style={{'color': 'red'}}>Failed to update your profile. Please try again.</small> : <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default EditProfile;