import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";

export const useToken = () => {
    // const navigate = useNavigate();
    const [token, setTokenInternal] = useState(() => {
        var dateNow = new Date();
        var tok = localStorage.getItem('token');
        if(!tok){
            return tok;
        }
        var decodedToken = jwt_decode(tok);
        if(decodedToken.exp < parseInt(dateNow.getTime().toString().slice(0, -3))){
            localStorage.removeItem('token')
            window.location.reload(false)
            // navigate('/login')
        }  
        else{
            return tok;
        }
    });

    const setToken = newToken => {
        localStorage.setItem('token', newToken);
        setTokenInternal(newToken);
    }

    return [token, setToken];
}