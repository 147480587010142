import './ThemePreview.css';
import { useUser } from '../auth/useUser';
import { useToken } from '../auth/useToken';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import '../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/icons/uicons-solid-rounded/css/uicons-solid-rounded.css';

export default function ThemePreview(props) {
    const theme = props.theme;

    const type = props.type;


    return(
        <Link to={`/themes/view/${theme._id}`} style={{ textDecoration: 'none' }}>
            <div className='theme-preview'>
                <div className='theme-preview-palette-container'>
                    {theme.colors.map((color, index)=> {
                        return(
                            <div id={`${type}-theme-preview-${theme._id}`} key={index} className='theme-preview-palette-cell' style={{"background": color}}>

                            </div>
                        )
                    })}
                </div>
                <h6 className='theme-preview-name'>{`${theme.name}${'\xa0'.repeat(1)}`} 
                {
                    !theme.isShared ? 
                    <i className="fi fi-rr-eye-crossed theme-preview-lock icon-centered"></i>
                    :
                    <></>
                }
                
                </h6>
                {/* <i id={`${type}-theme-preview-icon-${theme._id}`} className='fi fi-rr-arrow-right theme-preview-arrow'></i> */}
            </div>
        </Link>
    )
}