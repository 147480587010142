
export default function compileThemeReducer(data) {
    const jsonData = {
        "name": data.name,
        "dataColors": data.colors,
        "foreground" : data.textColor,
        "visualStyles" : {
            "page": {
                "*" : {
                    "background": [
                        {
                            "color": {
                                "solid": {
                                    "color": data.pageBackground
                                }
                            },
                            "transparency": data.backgroundTransparency ? parseInt(data.backgroundTransparency) : 0
                        }
                    ]
                }
            }
        }
        ,
        "textClasses" : data.universalFont?  {
            "label": {
                "color": "#FFFFFF",
                "fontFace": data.font,
                "fontSize": 9
            },
            "callout": {
                "color": "#FFFFFF",
                "fontFace": data.font,
            },
            "title": {
                "color": "#D34E59",
                "fontFace": data.font,
                "fontSize": 10
            },
            "header": {
                "color": "#FFFFFF",
                "fontFace": data.font,
            }
            
        }
        :
        {
            "label": {
                "color": data.advancedFonts.labels.color,
                "fontFace": data.advancedFonts.labels.fontFace,
                "fontSize": data.advancedFonts.labels.fontSize
            },
            "callout": {
                "color": data.advancedFonts.callouts.color,
                "fontFace": data.advancedFonts.callouts.fontFace,
                "fontSize": data.advancedFonts.callouts.fontSize
            },
            "title": {
                "color": data.advancedFonts.titles.color,
                "fontFace": data.advancedFonts.titles.fontFace,
                "fontSize": data.advancedFonts.titles.fontSize
            },
            "header": {
                "color": data.advancedFonts.headers.color,
                "fontFace": data.advancedFonts.headers.fontFace,
                "fontSize": data.advancedFonts.headers.fontSize
            }
            
        }
    };

    return(JSON.stringify(jsonData));
}