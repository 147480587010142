import {Routes, Route, Link, useLocation, Navigate,  useSearchParams, useNavigate} from "react-router-dom";
import Login from './components/Login';
import Signup from './components/Signup';
import Home from './components/Home'
import { useUser } from './auth/useUser';
import RequireAuth from './auth/RequireAuth';
import Create from './components/Create';
import ViewTheme from './components/ViewTheme';
import Landing from './pages/Landing';
import Explore from './pages/Explore';
import { useEffect, useCallback, useState } from 'react';
import ViewProfile from './components/ViewProfile';
import Edit from './components/Edit';
import EditProfile from './components/EditProfile';
import EmailVerificationLanding from './components/EmailVerificationLanding';
import ResetPasswordLanding from './components/PasswordResetLanding';
import Browse from './pages/Browse';
import Extract from "./components/Extract";
import Terms from "./components/term/terms";
import Help from "./components/Help";
import OpenAi from "./pages/OpenAi";
import './external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import './external/icons/uicons-solid-rounded/css/uicons-solid-rounded.css';
import './external/icons/uicons-bold-rounded/css/uicons-bold-rounded.css';
import './external/icons/uicons-brands/css/uicons-brands.css';

import './App.css';
import NotFound from "./pages/NotFound";


function App() {
  

  const user = useUser();
  const navigate = useNavigate();
  const [showScrim, setShowScrim] = useState(false);

  function handleLogout() {
    setShowScrim(!showScrim);
    localStorage.removeItem('token');
    navigate('/login');
    window.location.reload(false);
  }

  const onSignInClicked = () => {
    handleMenuNavigate('login')
  }

  const handleNavigate = useCallback((destination) => navigate(`/${destination}`, {replace: false}), [navigate]);

  function handleMenuNavigate (destination) {
    setShowScrim(!showScrim);
    document.getElementById('app-menu').classList.remove('nav-shown')
    document.getElementById('app-menu-icon').classList.add('fi-rr-menu-burger')
    document.getElementById('app-menu-icon').classList.remove('fi-rr-cross')
    handleNavigate(destination);
  }

  const onMenuClicked = () => {
    if (showScrim === true) {
      setShowScrim(false)
    } else {
      setShowScrim(true)
    }
    document.getElementById('app-menu').classList.toggle('nav-shown');
    document.getElementById('app-menu').focus();
    document.getElementById('app-menu-icon').classList.toggle('fi-rr-menu-burger');
    document.getElementById('app-menu-icon').classList.toggle('fi-rr-cross');
  }


  return (
    <div className="App" style={{"position" : "relative", "minHeight": "100%", "display": "flex", "flexDirection": "column", "background": "#404040"}}>
      {
        showScrim ? 
        <div onClick={() => onMenuClicked()} className='scrim'>
        </div> : <></>
      }
      <div id="main-container" className="container-fluid" style={{"padding": "0px", "paddingBottom": "10rem", "flex": "1 0 auto", "height": "100%"}}>
        <nav className="navbar fixed-top app-navbar">
            <div className="container-fluid navbar-content">
                <Link to={'/'} style={{"textDecoration": "none"}}>
                  <img className='navbar-logo' src="/right_aligned_logo.png" alt='Company Logo'>
                  </img>
                </Link>
                <div id='app-menu' className="nav-ul">
                  <div onClick={() => handleMenuNavigate('')} className="dropdown">         
                      <button className="btn btn-secondary shadow-none  nav-dropdown" type="button" id="dropdownMenuButton" aria-expanded="false">
                        Home
                      </button>
                      
                  </div>
                  <div className="dropdown">
                        <button className="btn btn-secondary shadow-none dropdown-toggle nav-dropdown" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Create
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                          <div onClick={() => handleMenuNavigate('ai')} className="dropdown-item nav-dropdown-item"><i className="fi fi-rr-brain-circuit icon-centered"></i>{`${'\xa0'}Use Generative AI (new)`}</div>
                          <div onClick={() => handleMenuNavigate('extract')} className="dropdown-item nav-dropdown-item"><i className="fi fi-rr-picture icon-centered"></i>{`${'\xa0'}Extract from an Image`}</div>
                          <div onClick={() => handleMenuNavigate('create')} className="dropdown-item nav-dropdown-item"><i className="fi fi-rr-plus icon-centered"></i>{`${'\xa0'}Create Manually`}</div>
                        </div>
                  </div>
                  <div className="dropdown">
                        <button className="btn btn-secondary shadow-none dropdown-toggle nav-dropdown" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Explore
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <div onClick={() => handleMenuNavigate('explore/')} className="dropdown-item nav-dropdown-item"><i className="fi fi-rr-search icon-centered"></i>{`${'\xa0'}Search`}</div>
                            <div onClick={() => handleMenuNavigate('browse/')} className="dropdown-item nav-dropdown-item"><i className="fi fi-rr-resources icon-centered"></i>{`${'\xa0'}Browse All`}</div>
                        </div>
                        
                  </div>
                  {user ?
                    <div className="dropdown">
                    <button className="btn btn-secondary shadow-none dropdown-toggle nav-dropdown" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Account
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <div onClick={() => handleMenuNavigate(`profile/view/${user.id}`)} className="dropdown-item nav-dropdown-item" href="#"><i className="fi fi-rr-user icon-centered"></i>{`${'\xa0'}Profile`}</div>
                      {/* <a className="dropdown-item nav-dropdown-item" href="#">Subscription</a> */}
                      <div onClick={()=>handleLogout()} className="dropdown-item nav-dropdown-item" style={{"cursor": "pointer"}}><i className="fi fi-rr-sign-out-alt icon-centered"></i>{`${'\xa0'}Sign Out`}</div>
                    </div>
                    </div> 
                    : 
                    <button onClick={onSignInClicked} className='header-login-button'>Sign In</button>
                  }
                  <i onClick={() => handleMenuNavigate('help')} className ={`fi fi-rr-interrogation help-icon-header ${user ? '' : 'help-icon-header-padded'}`}></i>
                
                </div>
                <div onClick={() => onMenuClicked() } className='nav-burger-menu-div'>
                  <i id='app-menu-icon' className="fi fi-rr-menu-burger nav-burger"></i>
                </div>
              </div>
        </nav>
        {/* beginning of main content */}
        <div className="container-fluid" style={{"padding"  :"0px", "height": "100%"}}>
          <Routes>
            <Route path='/' element={<Landing/>} />
            <Route path='/login/' element ={<Login/>} />
            <Route path='/signup/' element ={<Signup/>} />
            <Route path='/home' element={<RequireAuth><Home/></RequireAuth> } />
            <Route path='/create/' element={<Create/>} />
            <Route path='/themes/view/:id' element={<ViewTheme/>} />
            <Route path='/explore' element={<Explore/>} />
            <Route path='/edit/:id' element={<RequireAuth><Edit/></RequireAuth> } />
            <Route path='/profile/view/:id' element={<ViewProfile/>} />
            <Route path='/profile/edit' element={<RequireAuth><EditProfile/></RequireAuth>}/>
            <Route path='/verify-email/:verificationString' element={<EmailVerificationLanding/>}/>
            <Route path='reset-password/:resetPasswordCode' element={<ResetPasswordLanding/>}/>
            <Route path='/browse' element={<Browse/>}/>
            <Route path='/extract' element={<Extract/>}/>
            <Route path='/terms' element={<Terms/>}/>
            <Route path="/help" element={<Help/>}/>
            <Route path="/ai" element={<OpenAi></OpenAi>} />
            <Route path='*' element={<NotFound/>}></Route>
          </Routes>
        </div>
        {/* end of main content */}
        
      </div>
      {/* footer */}
      <footer className='container-fluid footer'>
        <div className='row container '>
            <div className=' col-md-6 col-sm-12 footer-column'>
                <p className='footer-text'>Copyright Power Palette Inc. 2022</p>
                <a href='/terms'><p className='footer-text' style={{"color": "white", "textDecoration": "none"}}>Terms of Service</p></a>
            </div>
            <div className='col-md-6 col-sm-12 footer-column'>
            <a href="https://www.buymeacoffee.com/dwaynethomL"><img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=dwaynethomL&button_colour=FFDD00&font_colour=000000&font_family=Inter&outline_colour=000000&coffee_colour=ffffff" /></a>
            </div>
        </div>
      </footer>

    </div>
  );
}

export default App;
