import './Explore.css';
import './OpenAi.css';
import { useUser } from '../auth/useUser';
import '../components/css/shared.css';
import { useToken } from '../auth/useToken';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ThemeLink from '../components/ThemeLink';
import '../external/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/icons/uicons-solid-rounded/css/uicons-solid-rounded.css';
import ThemePreview from '../components/ThemePreview';
import ReactPaginate from 'react-paginate';
import '../components/css/pagination.css';
import getAppInsights from '../utils/logging';
import Breadcrumb from '../components/Breadcrumb';



export default function Explore() { 
    const user = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const [,setToken] = useToken();
    const [recentThemes, setRecentThemes] = useState();
    const [popularThemes, setPopularThemes] = useState();
    const [mode, setMode] = useState('browse');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState();
    const [searchTermStatic, setSearchTermStatic] = useState();
    const [searchResultsSplit, setSearchResultsSplit] = useState();
    const [currentItems, setCurrentItems] = useState();

    const appInsights = getAppInsights();
    appInsights.loadAppInsights();

    const crumbs = [
        {
            text: "Home",
            url: "/"
        },
        {
            text: "Search",
            url: "/explore"
        }
    ]
    
    useEffect(() => {
        appInsights.trackPageView();
    }, [appInsights])

    const tweakSearchIcon = ()  => {
        let icon = document.getElementById('search-icon');
        icon.classList.toggle('search-icon-rotated')
    }

    useEffect(() => {
        if(searchResults) {
            const perChunk = 40;
            const result = [];
            for (let i = 0; i < searchResults.length; i += perChunk) {
                const chunk = searchResults.slice(i, i+perChunk);
                result.push(chunk)
            }
            setSearchResultsSplit(result);
            setCurrentItems(result[0])
        }
    }, [searchResults])

    const fetchThemes = async () => {
        const response = await axios.post('/themes/get/recent', {limit: 5});
        setRecentThemes(response.data);
        const pop_response = await axios.post('/themes/get/popular', {limit: 5});
        setPopularThemes(pop_response.data)
    }

    useEffect(() => {
        fetchThemes();
    }, [])

    const handleSearchClicked = async () => {
        if (searchTerm !== ""){
            setSearchTermStatic(searchTerm);
            const results = await axios.post('/themes/search', {limit: 25, term: searchTerm});
            setSearchResults(results.data);
            setMode('search');
        }
    }

    const handleClearSearch = () => {
        setMode('browse');
        setSearchResults([]);
        setSearchTerm("");
        setSearchTermStatic("");
        document.getElementById('main-search').value = "";

    }

    function Items(props) {
        return (
            <div className='preview-container' >
            {
                props.themes ? 
                props.themes.map((theme, index) => {
                    return (
                        <ThemePreview key={index} theme={theme} type='search'/>
                    )
                })
                :
                <></>
            }
        </div>
        )
    }

    const handlePageClick = (event) => {
        const pageIndex = event.selected;
        setCurrentItems(searchResultsSplit[pageIndex])
    }

    return (
        <div className="container-fluid" style={{"paddingTop": "70px", "paddingLeft" : "0px", "paddingRight": "0px"}}>
            <Breadcrumb crumbs={crumbs}></Breadcrumb>
            <div className='section-darkgray'>
                <div className='container search-container'>
                    <label className='search-label'>Search</label>
                    <div className="ai-search-input main-search">
                        <input onChange={(e) => setSearchTerm(e.target.value)} id='main-search' className="ai-search-input-input main-search-input"></input>
                        <i onClick={handleSearchClicked} onMouseEnter={tweakSearchIcon} onMouseLeave={tweakSearchIcon}  id="search-icon" className="fi fi-rr-search search-icon"></i>
                    </div>
                </div>
            </div>
            
            {
                mode==='browse' ? 
                <>
                <div className='container'>
                    <div className="strike strike-white">
                        <span style={{"color": "#ECECEC"}}>Popular</span>
                    </div>
                    <div className='container'>
                        {
                            popularThemes? 
                            popularThemes.map((theme, index)=>{
                                return(
                                    <ThemeLink key={index} theme={theme} type='popular'/>
                                )
                            })
                            :
                            <div className="spinner-grow" role="status" style={{"width": "2rem", "height": "2rem"}}>
                            <span className="sr-only">Loading...</span>
                            </div>
                        }
                        <Link style={{"textDecoration": "none"}} to='/browse'>
                            <h5 style={{"display": "flex", "paddingTop": "10px"}} ><span className='explore-gallery-prompt'>Browse all themes{'\xa0'}</span><i className="fi fi-rr-arrow-right icon-centered explore-gallery-prompt-icon"></i></h5>
                        </Link>
                    </div>
                </div>
                <div className='container' style={{"paddingTop": "1rem"}}>
                    <div className="strike strike-white">
                        <span style={{"color": "#ECECEC"}}>Recently Added</span>
                    </div>
                    <div className='container'>
                        {
                            recentThemes? 
                            recentThemes.map((theme, index)=>{
                                return(
                                    <ThemeLink key={index} theme={theme} type='recent'/>
                                )
                            })
                            :
                            <div className="spinner-grow" role="status" style={{"width": "2rem", "height": "2rem"}}>
                            <span className="sr-only">Loading...</span>
                            </div>
                        }
                        <Link style={{"textDecoration": "none"}} to='/browse'>
                            <h5 style={{"display": "flex", "paddingTop": "10px"}} ><span className='explore-gallery-prompt'>Browse all themes{'\xa0'}</span><i className="fi fi-rr-arrow-right icon-centered explore-gallery-prompt-icon"></i></h5>
                        </Link>
                    </div>
                </div>
            </>
            :
            <div className='container'>
                <div style={{"display": "flex", "flexDirection": "column", "justifyItems": "flex-start", "paddingTop": "15px"}}>
                    <button onClick={() => handleClearSearch()} className='delete-confirm-button' style={{"width": "max-content"}}>Clear Search</button>
                    <h3 className='search-header'>{`Found ${searchResults.length} result${searchResults.length === 1 ? "" : "s"} for "${searchTermStatic}":`}</h3>
                </div>
                {
                    searchResultsSplit ?
                    <>
                    <Items themes={currentItems}></Items>
                    <ReactPaginate
                    className='search-pagination' 
                    breakLabel="..."
                    nextLabel= {<i className="fi fi-rr-arrow-right pagination-icon"></i>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={searchResultsSplit.length}
                    previousLabel= {<i className="fi fi-rr-arrow-left pagination-icon"></i>}
                    renderOnZeroPageCount={null}/>
                    </>
                    :
                    <></>

                }
                
                
            </div>
            }
            
            
        </div>
    )
}
